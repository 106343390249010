import 'chart.js/auto';
import { Line } from 'react-chartjs-2';

import { FC } from 'react';
import {
  Box,
  Card,
  CardContent,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import StorageTwoToneIcon from '@mui/icons-material/StorageTwoTone';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CallMadeTwoToneIcon from '@mui/icons-material/CallMadeTwoTone';

import { useUser } from 'store/user/hooks';
import { getHumanReadableDate } from 'core/utils/time';

import PicHomeSrc from 'assets/Dashboard/picHome.png';
import logoSaleforceSrc from 'assets/Dashboard/logoSaleforce.png';

import { IconCard } from './components/IconCard';
import { AiAssistantCard } from './components/AiAssistantCard';
import { DashboardPageTabs } from './DashboardPageTabs';

const mockedLineChart = (
  <Box padding={0} height={70} width={'100%'}>
    <Line
      data={{
        labels: ['1', '3', '5', '7', '9', '11', '13', '15'],
        datasets: [
          {
            borderWidth: 2,
            borderColor: '#8CB9FF',
            data: [2, 5.5, 2, 8.5, 1.5, 5, 7, 5],
          },
        ],
      }}
      options={{
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: { display: false },
          y: { display: false },
        },
      }}
      style={{
        margin: 0,
        padding: 0,
        width: '100%',
        height: '100%',
      }}
    />
  </Box>
);

const HeaderCard: FC<{ user: any }> = ({ user }) => (
  <Box
    p={2}
    height={230}
    display="flex"
    borderRadius={1}
    justifyContent="space-between"
    sx={{
      backgroundSize: 'auto',
      backgroundColor: '#1959FF',
      backgroundPosition: 'center',
      backgroundImage: `url(${PicHomeSrc})`,
    }}
  >
    <Box color={'white'} display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
      <div>
        <Typography variant="h2" fontSize={24}>
          Hi, {user?.userName} <br />
        </Typography>
        <Typography variant="p12">
          Welcome Back to Customer City! <br />
        </Typography>
      </div>
      <Typography variant="p12">Insights from Data Quality to Visualization</Typography>
    </Box>
    <Card
      sx={{
        width: '40%',
        py: 1.5,
        px: 1,
      }}
    >
      <CardContent>
        <Typography display={'flex'} variant="labelRegular12" justifyContent={'space-between'}>
          <span style={{ textTransform: 'uppercase' }}>Last Data Sync</span>
          <span style={{ color: '#898EA1' }}>
            21m ago
            <br />
          </span>
        </Typography>
        <Typography variant="labelRegular12">
          {getHumanReadableDate(user?.userUpdatedAt.toString() ?? Date.toString())}
          <br />
        </Typography>
        <Divider sx={{ my: 2.5 }} />
        <Typography variant="labelRegular12" textTransform={'capitalize'}>
          TASKS TODAY
        </Typography>
        <LinearProgress variant="determinate" value={50} />
        <FormHelperText
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <span>2 done</span>
          <span>of 4</span>
        </FormHelperText>
      </CardContent>
    </Card>
  </Box>
);

const DashboardPage: FC = () => {
  const { user } = useUser();

  const iconCardsData = [
    {
      title: 'New Business Opps',
      count: '8,930',
      borderColor: '#5990E4',
      icon: <LocalMallIcon fontSize="small" htmlColor="#5990E4" />,
    },
    {
      title: 'Expansion Opps',
      count: '4,345',
      borderColor: '#5990E4',
      icon: <LocalMallIcon fontSize="small" htmlColor="#5990E4" />,
    },
    {
      title: 'Total Opps',
      count: '10,019',
      borderColor: '#5990E4',
      icon: <LocalMallIcon fontSize="small" htmlColor="#5990E4" />,
    },
    {
      title: 'Data Source',
      count: 'Salesforce',
      borderColor: '#5990E4',
      icon: <StorageTwoToneIcon fontSize="small" htmlColor="#5990E4" />,
      isDataSource: true,
    },
    {
      title: 'Anomalies Alert',
      count: '12',
      borderColor: '#FF9960',
      icon: <ReportProblemIcon fontSize="small" htmlColor="#FF9960" />,
    },
    {
      title: 'Overall Data Health',
      count: '90%',
      borderColor: '#60C67C',
      icon: <CallMadeTwoToneIcon fontSize="small" htmlColor="#60C67C" />,
    },
  ];

  return (
    <Grid
      container
      paddingY={3}
      spacing={2}
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{ height: '100%' }}
    >
      <Grid item xs={12} md={6}>
        <HeaderCard user={user} />
      </Grid>
      <Grid item xs={12} md={6}>
        <AiAssistantCard />
      </Grid>
      {iconCardsData.map(({ title, count, borderColor, icon, isDataSource }, index) => (
        <Grid item key={index} xs={2} position={'relative'}>
          <IconCard iconBorder={`1px solid ${borderColor}`} icon={icon}>
            <Typography variant="p12" py={1}>
              {title}
            </Typography>
            <Typography variant="h2" fontSize={isDataSource ? 16 : 32}>
              {count}
            </Typography>
            {isDataSource ? <img src={logoSaleforceSrc} alt="logoSaleforce" /> : mockedLineChart}
            {isDataSource ? (
              <Stack sx={{ minWidth: 0 }}>
                <Typography noWrap variant="labelRegular12">
                  Status :
                  <IconButton size="small">
                    <CheckCircleIcon fontSize="small" htmlColor="#27AE60" sx={{ transform: 'scale(.8)' }} />
                  </IconButton>
                </Typography>
              </Stack>
            ) : (
              <Link fontSize={14} style={{ cursor: 'pointer', textDecoration: 'none' }}>
                More
              </Link>
            )}
          </IconCard>
        </Grid>
      ))}
      <Grid item xs={12}>
        <DashboardPageTabs />
      </Grid>
    </Grid>
  );
};

export default DashboardPage;
