import React from 'react';
import { InteractionData } from './DataHealthPanel';
import { Box, Divider, LinearProgress, Paper, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

type TooltipProps = {
  interactionData: InteractionData | null;
  width: number;
  height: number;
};

function getColor(minValue: number, mediunValue: number, maxValue: number, value: number) {
  if (minValue <= value && mediunValue > value) {
    return '#FB4E6D';
  } else if (mediunValue <= value && maxValue > value) {
    return '#FF9960';
  } else if (maxValue <= value) {
    return '#60C67C';
  }

  return 'white';
}

export const Tooltip = ({ interactionData, width, height }: TooltipProps) => {
  if (!interactionData) {
    return null;
  }

  return (
    <div
      style={{
        width,
        height,
        zIndex: 1,
        position: 'absolute',
        top: 0,
        left: 0,
        pointerEvents: 'none',
      }}
    >
      <div
        style={{
          position: 'absolute',
          left: interactionData.xPos,
          top: interactionData.yPos,
        }}
      >
        <Paper sx={{ width: 270, padding: 2, backgroundColor: '#0d2045' }}>
          <Typography color={'white'} fontSize={12} fontWeight={600}>
            {interactionData.companyName}
          </Typography>
          <Box display="flex" fontSize={12} color={'#9da1b5'} justifyContent="space-between" pt={2}>
            <Typography fontSize={12}>Revenue:</Typography>
            <Typography fontSize={12} fontWeight={600} color={'white'}>
              {interactionData.revenue}M
            </Typography>
          </Box>
          <Box display="flex" fontSize={12} color={'#9da1b5'} justifyContent="space-between">
            <Typography fontSize={12}>Accounts Owner:</Typography>
            <Typography fontSize={12} fontWeight={600} color={'white'}>
              {interactionData.accountOwner}
            </Typography>
          </Box>
          <Box display="flex" fontSize={12} color={'#9da1b5'} justifyContent="space-between">
            <Typography fontSize={12}>Last Contact Date:</Typography>
            <Typography fontSize={12} fontWeight={600} color={'white'}>
              {interactionData.lastContactDate}
            </Typography>
          </Box>
          <br />
          <Box display="flex" fontSize={12} color={'#9da1b5'} justifyContent="space-between">
            <Typography fontSize={12}>Leads:</Typography>
            <Typography fontSize={12} fontWeight={600} color={'white'}>
              {interactionData.leads}
            </Typography>
          </Box>
          <Box display="flex" fontSize={12} color={'#9da1b5'} justifyContent="space-between">
            <Typography fontSize={12}>Opportunities:</Typography>
            <Typography fontSize={12} fontWeight={600} color={'white'}>
              {interactionData.opportunities}
            </Typography>
          </Box>
          <Box display="flex" fontSize={12} color={'#9da1b5'} justifyContent="space-between" pb={2}>
            <Typography fontSize={12}>Contacts:</Typography>
            <Typography fontSize={12} fontWeight={600} color={'white'}>
              {interactionData.contacts}
            </Typography>
          </Box>
          <Divider sx={{ backgroundColor: '#9da1b5' }} />
          <Box display="flex" color="#9da1b5" justifyContent="space-between" pt={1} alignItems="center">
            <Typography fontSize={12}>Confidence Score:</Typography>
            <Box position="relative" display="inline-flex" width="80px">
              <LinearProgress
                value={interactionData.confidenceScore}
                variant="determinate"
                sx={{
                  borderRadius: '8px',
                  width: '100%',
                  height: '20px',
                  backgroundColor: '#F6F8FB',
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: getColor(0, 33, 66, interactionData.confidenceScore),
                  },
                }}
              />
              <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  sx={{ fontSize: '10px', color: 'black' }}
                >{`Average ${interactionData.confidenceScore}%`}</Typography>
              </Box>
            </Box>
          </Box>
        </Paper>
      </div>
    </div>
  );
};
