import { faker } from '@faker-js/faker';
import { FC, useEffect, useRef, useState } from 'react';

import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { Box, Chip, IconButton, LinearProgress, Link, Tooltip, Typography } from '@mui/material';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

import { ColumnSortedAscendingIcon, ColumnSortedDescendingIcon, ColumnUnsortedIcon } from 'pages/DataRaptor/ui';
import { DataAnomalyTable } from './DataAnomalyTable';

export interface ConfidenceScoreBreakdownData {
  id: number;
  average: number;
  account: string;
  impactScore: number;
  previousMonthAverage: number;
}

const getColor = (minValue: number, mediumValue: number, maxValue: number, value: number) => {
  if (value < mediumValue) return value < minValue ? '#FB4E6D' : '#FF9960';
  return value < maxValue ? '#60C67C' : '#60C67C';
};

const getPreviousAndCurrentMonthAndYear = (): [string, string] => {
  const date = new Date();
  date.setMonth(date.getMonth() - 1);
  const formatter = new Intl.DateTimeFormat('en-US', { month: 'short', year: 'numeric' });

  return [formatter.format(date).replace(',', ''), formatter.format(new Date()).replace(',', '')];
};

const createColumns = (
  width: number,
  [previousMonth, currentMonth]: [string, string],
  pushTable?: (title: string, subtitle: string, table: JSX.Element) => void,
): GridColumns<ConfidenceScoreBreakdownData> => [
  {
    field: 'account',
    headerName: 'Account',
    width: 0.3 * width,
    renderCell: ({ row }) => (
      <Tooltip title={row.account}>
        <Link underline="none">{row.account}</Link>
      </Tooltip>
    ),
  },
  {
    field: 'average',
    headerName: previousMonth,
    width: 0.2 * width,
    renderCell: ({ row }) => (
      <Box position="relative" display="inline-flex" width="100%">
        <LinearProgress
          variant="determinate"
          value={row.average}
          sx={{
            borderRadius: '8px',
            width: '100%',
            height: '24px',
            backgroundColor: '#F6F8FB',
            '& .MuiLinearProgress-bar': {
              backgroundColor: getColor(0, 33, 66, row.average),
            },
          }}
        />
        <Box
          position="absolute"
          top={0}
          left={0}
          bottom={0}
          right={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography sx={{ fontSize: '10px' }}>{`Average ${row.average}%`}</Typography>
        </Box>
      </Box>
    ),
  },
  {
    field: 'previousMonthAverage',
    headerName: `vs ${currentMonth}`,
    width: 0.2 * width,
    align: 'center',
    renderCell: ({ row }) => (
      <Chip
        size="small"
        label={row.previousMonthAverage}
        sx={{
          color: 'white',
          backgroundColor: getColor(-100, 0, 50, row.previousMonthAverage),
        }}
      />
    ),
  },
  {
    field: 'impactScore',
    headerName: 'Impact on Overall Score',
    width: 0.2 * width,
    align: 'center',
    renderCell: ({ row }) => (
      <Chip
        size="small"
        label={row.impactScore}
        sx={{
          color: 'white',
          backgroundColor: getColor(-1, 0, 0.5, row.impactScore),
        }}
      />
    ),
  },
  {
    field: 'actions',
    headerName: '',
    width: 0.1 * width,
    align: 'center',
    disableColumnMenu: true,
    renderCell: ({ row }) => (
      <Tooltip title={`View ${row.account} anomalies`}>
        <IconButton onClick={() => pushTable?.('Data Anomalies', row.account, <DataAnomalyTable row={row} />)}>
          <VisibilityOutlinedIcon fontSize="small" htmlColor="#1554FF" />
        </IconButton>
      </Tooltip>
    ),
  },
];

const generateFakeData = (): ConfidenceScoreBreakdownData[] =>
  Array.from({ length: 23 }, (_, id) => ({
    id: id + 1,
    account: faker.company.name(),
    average: faker.number.int({ min: 0, max: 100 }),
    previousMonthAverage: faker.number.int({ min: -100, max: 100 }),
    impactScore: faker.number.float({ min: -1, max: 1, precision: 0.1 }),
  }));

export type ConfidenceScoreBreakdownTableProps = {
  pushTable?: (title: string, subtitle: string, table: JSX.Element) => void;
};

export const ConfidenceScoreBreakdownTable: FC<ConfidenceScoreBreakdownTableProps> = ({ pushTable }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [columns, setColumns] = useState<GridColumns<ConfidenceScoreBreakdownData>>([]);
  const [previousMonth, currentMonth] = getPreviousAndCurrentMonthAndYear();
  const dataRows = useRef(generateFakeData()).current;

  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        setColumns(createColumns(ref.current.clientWidth, [previousMonth, currentMonth], pushTable));
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [previousMonth, currentMonth, pushTable]);

  return (
    <Box ref={ref} width="100%" height="100%">
      <DataGrid
        disableColumnFilter
        disableColumnSelector
        disableSelectionOnClick
        rows={dataRows}
        columns={columns}
        rowHeight={49}
        headerHeight={30}
        pageSize={5}
        rowsPerPageOptions={[5]}
        getRowId={(row) => row.id}
        density="comfortable"
        components={{
          ColumnUnsortedIcon,
          ColumnSortedAscendingIcon,
          ColumnSortedDescendingIcon,
        }}
      />
    </Box>
  );
};
