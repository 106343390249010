import { GridColumns } from '@mui/x-data-grid';
import { faker } from '@faker-js/faker';
import { getHumanReadableDate } from 'core/utils/time';
import { Avatar, Box, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';

export interface IntegrationActivityData {
  id: string;
  type: string;
  updatedAt?: Date;
  user: {
    name: string;
    mailAddress: string;
  };
  table: {
    name: string;
    field: string;
    updatedValue: any;
    previousValue: any;
  };
}

const avatarSize = 30;

export const getIntegrationActivityDataColumns = (width: number): GridColumns<IntegrationActivityData> => [
  {
    width: width * 0.2,
    field: 'updatedAt',
    headerName: 'Last Update',
    valueFormatter: (p) => (p.value ? getHumanReadableDate(p.value) : 'N/A'),
  },
  {
    width: width * 0.2,
    field: 'user.name',
    headerName: 'User Name',
    renderCell: ({ row }) => (
      <Box display="flex" alignItems="center">
        <Avatar sx={{ width: avatarSize, height: avatarSize, mr: 1 }}>
          <PersonIcon fontSize="small" />
        </Avatar>
        <Box>
          <Typography variant="body2" m={0} p={0}>
            {row.user.name}
            <br />
          </Typography>
          <Typography variant="body2" color="#898EA1" m={0} p={0}>
            {row.user.mailAddress}
            <br />
          </Typography>
        </Box>
      </Box>
    ),
  },
  {
    field: 'type',
    width: width * 0.1,
    renderCell: ({ row }) => (
      <Box px={1} borderRadius={1} bgcolor="#DBE9FF" textTransform="capitalize">
        {row.type}
      </Box>
    ),
  },
  {
    width: width * 0.1,
    field: 'table.name',
    headerName: 'Table',
    renderCell: ({ row }) => (
      <Box px={1} borderRadius={1} fontWeight="bold" textTransform="capitalize">
        {row.table.name}
      </Box>
    ),
  },
  {
    width: width * 0.1,
    headerName: 'Field',
    field: 'table.field',
    renderCell: ({ row }) => (
      <Box px={1} borderRadius={1} fontWeight="bold" textTransform="capitalize">
        {row.table.field}
      </Box>
    ),
  },
  {
    width: width * 0.13,
    field: 'table.previousValue',
    headerName: 'Previous Value',
    renderCell: ({ row }) => row.table.previousValue ?? 'N/A',
  },
  {
    width: width * 0.17,
    field: 'table.updatedValue',
    headerName: 'Updated Value',
    renderCell: ({ row }) => row.table.updatedValue ?? 'N/A',
  },
];

export const mockedData = Array.from({ length: 10 }, (_, index) => ({
  type: 'updated',
  id: index.toString(),
  updatedAt: new Date(),
  user: {
    name: `John Smith #${index}`,
    mailAddress: `johnsmith${index}@gmail.com`,
  },
  table: {
    name: 'Contact',
    field: 'Email',
    previousValue: null,
    updatedValue: `newMail${index}@gmail.com`,
  },
}));
