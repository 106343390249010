import { apiCall } from '../index';
import { AIPoweredRuleTemplateResponse } from 'store/ruleLibrary/types';
import { AIRuleContext, AISuggestions } from '../../store/ruleForm/types';

const baseURL = process.env.REACT_APP_GATEWAY_URL;

export enum RecommendationActionEnum {
  ACCEPT = 'accept',
  IGNORE = 'reject',
}

export interface GetSmartMergeRequest {
  ids: string[];
  tableName: string;
}

export interface GetDataAnomalySummaryRequest {
  ruleId: string;
  record: any;
  details: string[];
  tableName: string;
  recordId: string;
}

export interface GetDataAnomalyFieldRecommendationRequest {
  fieldName: string;
  tableName: string;
  ruleId: string;
  record: any;
  recordId: string;
  details: string[];
}

interface GetTaskForDataAnomalyCompletionRequest {
  details: string[];
  record: any;
  tableName: string;
}

interface GetTaskForDataAnomalyCompletionResponse {
  completion: {
    subject: string | null;
    notes: string | null;
    dueDate: string | null;
  };
}

export interface GetDataAnomalySummaryResponse {
  summary: string;
}

export interface GetDataAnomalyFieldRecommendationResponse {
  recommendation: string;
}

export interface ChatMessage {
  type: 'AI' | 'human';
  text: string;
}

export interface RequestCompletionBody {
  userInput: string;
  chatHistory: ChatMessage[];
  suggestions: Partial<AISuggestions>;
  rule: Partial<AIRuleContext>;
}

export const getDataAnomalySummary = (
  migrationId: string,
  body: GetDataAnomalySummaryRequest,
): Promise<GetDataAnomalySummaryResponse> => {
  return apiCall({
    method: 'post',
    url: `/ai/migration/${migrationId}/completion/data-anomaly-summary`,
    baseURL,
    data: body,
  });
};

export const getDataAnomalyFieldRecommendation = (
  migrationId: string,
  body: GetDataAnomalyFieldRecommendationRequest,
): Promise<GetDataAnomalyFieldRecommendationResponse> => {
  return apiCall({
    method: 'post',
    url: `/ai/migration/${migrationId}/completion/data-anomaly-field-recommendation`,
    baseURL,
    data: body,
  });
};

export const postEmbedDDLSchema = (migrationId: string, forceUpdate = false): Promise<void> =>
  apiCall({ method: 'post', url: `/ai/migration/${migrationId}/embeddings/schemaDDL`, baseURL, data: { forceUpdate } });

export const getSmartMerge = (migrationId: string, data: GetSmartMergeRequest): Promise<{ aiSmartMerge: any }> =>
  apiCall({
    method: 'post',
    url: `/ai/migration/${migrationId}/completion/smart-merge`,
    baseURL,
    data,
  });

export const getAIRuleTemplate = (migrationId: string, templateId: string): Promise<AIPoweredRuleTemplateResponse> =>
  apiCall({
    method: 'get',
    url: `/ai/migration/${migrationId}/completion/template/${templateId}`,
    baseURL,
  });

export const getTaskForDataAnomalyCompletion = (
  migrationId: string,
  body: GetTaskForDataAnomalyCompletionRequest,
): Promise<GetTaskForDataAnomalyCompletionResponse> =>
  apiCall({
    method: 'post',
    url: `/ai/migration/${migrationId}/completion/task-for-anomaly-completion`,
    baseURL,
    data: body,
  });

export const updateAIRecommendationFeedBack = (
  migrationId: string,
  recommendationId: string,
  feedback: boolean,
): Promise<void> =>
  apiCall({
    method: 'put',
    url: `/ai-recommendation/migration/${migrationId}/feedback`,
    data: { id: recommendationId, liked: feedback },
  });

export const acceptAIRecommendation = (
  migrationId: string,
  recommendationId: string,
  fieldName: string,
  action: RecommendationActionEnum,
): Promise<void> =>
  apiCall({
    method: 'put',
    url: `/ai-recommendation/migration/${migrationId}/action`,
    data: { recommendationId, fieldName, action },
  });

export const ruleAssistantChat = (params: {
  migrationId: string;
  body: RequestCompletionBody;
  abortController?: AbortController;
}): Promise<{ response: string; chat: ChatMessage[]; suggestions: Partial<AISuggestions> }> => {
  return apiCall({
    method: 'post',
    url: `/ai/migration/${params.migrationId}/ruleAssistant/chat`,
    baseURL,
    data: params.body,
    signal: params.abortController?.signal,
  });
};
