import { useCallback, useEffect } from 'react';
import { useReactFlow, useViewport, useStoreApi } from 'reactflow';
import { getOpenLeads as getOpenLeadsApi } from 'http/graphData';
import { getMigrationTableDataById as getMigrationTableDataByIdApi } from 'http/migration';
import { useGraphRender } from 'store/graphRender/hooks';
import { useDataGraphUtils } from '../utils/useDataGraphUtils';
import { useGraphData } from 'store/graphData/hooks';
import { useMigration } from 'store/migration/hooks';
import { GraphObjectCounter, GraphRenderView } from 'store/graphRender/types';
import { ComponentDesign } from '../../types';
import { useGraphRenderLayoutUtils } from '../utils/useGraphRenderLayoutUtils';
import { useNavigate } from 'react-router-dom';
import { PRIVATE_ABS_ROUTE_PATHS } from 'core/constants';
import { useNeptuneDataHandlerUtils } from '../utils/useNeptuneDataHandlerUtils';
import { MIGRATION_STATUS_END_STATE } from 'store/graphData/types';
import { useGraphRenderDataHandlerUtils } from '../utils/useGraphRenderDataHandlerUtils';
import { BridgeNode, BridgeNodeTypeEnum } from '../../classes/BridgeNode';
import _, { debounce } from 'lodash';
import { useDuplicationDataHandlerUtils } from '../utils/useDuplicationDataHandlerUtils';
import { HideEventRelatedPostProcessing } from '../../utils/ComponentPostProcessing/HideEventRelatedPostProcessing';

const useGeneralViewEffects = (containerRef: React.MutableRefObject<HTMLObjectElement | undefined>) => {
  const reactFlow = useReactFlow();
  const { setNodes } = useStoreApi().getState();
  const { x: viewX, y: viewY, zoom: viewZoom } = useViewport();
  const { handleGraphComponentsLoad, addComponentsToGraph } = useGraphRenderDataHandlerUtils();
  const navigate = useNavigate();
  const { findRecordRelatedAccount } = useDataGraphUtils();
  const { checkDeduplicationResultByIds } = useDuplicationDataHandlerUtils();
  const { updateCustomNodesDesign, onLayout, triggerCollapseAnimation, edgesToExpand } = useGraphRenderLayoutUtils();
  const {
    data: {
      searching,
      selectedNodeFromSearchBar,
      view: graphRenderView,
      selectedNode,
      hoverAccountId,
      renderNodes,
      searchFilterOption,
      searchFilterResult,
      graphSearchResult,
      graphOpenLeadResult,
      graphOpenLeadResultCounter,
      schemaName,
      graphFilter,
      whiteEdgeBlockNodeId,
    },
    loading,
    setDefaultDesign,
    setLoading,
    setGraphObjectCounter,
    setSchemaName,
    setGraphSearchResult,
    setGraphOpenLeadResult,
    setGraphOpenLeadResultCounter,
    setSelectedNode,
    setSelectedNodeFromSearchBar,
    setWhiteEdgeBlockedNodeId,
  } = useGraphRender();

  const { getRecursiveNeptuneMigrationStatus } = useNeptuneDataHandlerUtils();

  const { migrationStatus: neptuneMigrationStatus, getDataByVertexIdArray } = useGraphData();

  const {
    data: { migrations, migrationId },
    getMigrations,
    setMigrationId,
    getMigrationTablesById,
  } = useMigration();

  useEffect(() => {
    if (graphRenderView) {
      setSelectedNode(undefined);
      setSelectedNodeFromSearchBar(undefined);
      setWhiteEdgeBlockedNodeId('');
    }
  }, [graphRenderView, setSelectedNode, setSelectedNodeFromSearchBar, setWhiteEdgeBlockedNodeId]);

  //Gets the migration data if there is no migration data redirect to data raptor
  useEffect(() => {
    setLoading(true);
    if (!migrations || migrations.length === 0) {
      getMigrations({
        onSuccess: (migrations) => {
          if (migrations.length === 0) navigate(PRIVATE_ABS_ROUTE_PATHS.dataRaptor);
        },
      });
    }
  }, [getMigrations, migrations, navigate, setLoading]);

  useEffect(() => {
    if (!migrationId && migrations && migrations.length > 0) {
      const migrationsCompleted = migrations.filter((migration) => migration.status === 'migration-completed');
      if (migrationsCompleted.length > 0) {
        setMigrationId(migrations[0].dataMigrationId);
      } else {
        navigate(PRIVATE_ABS_ROUTE_PATHS.dataRaptor);
      }
    }
  }, [migrationId, migrations, navigate, setMigrationId]);

  useEffect(() => {
    if (!schemaName && migrationId && migrations && migrations.length > 0) {
      const migrationsCompleted = migrations.filter((migration) => migration.dataMigrationId === migrationId);
      if (migrationsCompleted.length > 0) {
        const dataSource = migrations[0].dataSourceId.replace(/-/g, '_');
        const tenantId = migrations[0].tenantId;
        setSchemaName(`mig_${tenantId}_${dataSource}`);
      } else {
        navigate(PRIVATE_ABS_ROUTE_PATHS.dataRaptor);
      }
    }
  }, [schemaName, migrations, navigate, setSchemaName, migrationId]);

  useEffect(() => {
    if (migrationId) {
      getRecursiveNeptuneMigrationStatus();
    }
  }, [getRecursiveNeptuneMigrationStatus, migrationId]);

  useEffect(() => {
    if (
      neptuneMigrationStatus &&
      Object.values(MIGRATION_STATUS_END_STATE).includes(neptuneMigrationStatus.status as MIGRATION_STATUS_END_STATE)
    ) {
      getMigrationTablesById(migrationId);
    }
  }, [getMigrationTablesById, migrationId, neptuneMigrationStatus]);

  //Focus on Selected from Search Bar when searching
  useEffect(() => {
    if (selectedNodeFromSearchBar || selectedNode) {
      // const accountId = findRecordRelatedAccount(selectedNodeFromSearchBar.id || selectedNode.id);
      // const idToZoom = accountId || selectedNodeFromSearchBar.id || selectedNode.id;
      const idToZoom = selectedNodeFromSearchBar?.id || selectedNode?.id;
      if (!whiteEdgeBlockNodeId || whiteEdgeBlockNodeId === idToZoom) {
        reactFlow.fitView({ nodes: [{ id: idToZoom }], padding: 10, duration: 250 });
      }
    }
  }, [
    findRecordRelatedAccount,
    graphRenderView,
    reactFlow,
    searching,
    selectedNode,
    selectedNodeFromSearchBar,
    whiteEdgeBlockNodeId,
  ]);

  //Updates the design of the nodes when searching or view change
  useEffect(() => {
    if (searching) {
      if (searching && graphRenderView === GraphRenderView.ACCOUNT) {
        setDefaultDesign(ComponentDesign.SEARCHING);
      }
      if (searching && graphRenderView === GraphRenderView.DUPLICATES) {
        setDefaultDesign(ComponentDesign.DUPLICATE_INACTIVE);
      }
    } else if (graphRenderView === GraphRenderView.DUPLICATES) {
      setDefaultDesign(ComponentDesign.DUPLICATE_INACTIVE);
    } else if (graphRenderView === GraphRenderView.ACCOUNT || graphRenderView === GraphRenderView.LEADS) {
      {
        setDefaultDesign(ComponentDesign.STANDARD);
      }
    }
    updateCustomNodesDesign();
  }, [graphRenderView, searching, setDefaultDesign, updateCustomNodesDesign]);

  useEffect(() => {
    if (edgesToExpand.length > 0) {
      triggerCollapseAnimation(edgesToExpand);
    }
  }, [edgesToExpand, triggerCollapseAnimation]);

  useEffect(() => {
    if (loading === false && !!renderNodes) {
      onLayout({
        useInitialNodes: false,
      });
    }
  }, [loading, onLayout, renderNodes, searching]);

  useEffect(() => {
    if ([GraphRenderView.ACCOUNT, GraphRenderView.DUPLICATES].includes(graphRenderView)) {
      if (loading === false && renderNodes) {
        const graphObjectCounter: GraphObjectCounter = {};
        for (const key in renderNodes) {
          const object = renderNodes[key];
          if (object.data.label && object.type !== 'BridgeNode') {
            if (graphObjectCounter[object.data.label]) {
              graphObjectCounter[object.data.label] += 1;
            } else {
              graphObjectCounter[object.data.label] = 1;
            }
          }
        }
        setGraphObjectCounter(graphObjectCounter);
      }
    }
  }, [graphRenderView, loading, renderNodes, searching, setGraphObjectCounter]);

  //Updates the design of the nodes when hovering or selecting
  useEffect(() => {
    if ((selectedNode || selectedNodeFromSearchBar || hoverAccountId !== undefined) && searching) {
      updateCustomNodesDesign();
    }
  }, [graphRenderView, hoverAccountId, searching, selectedNode, selectedNodeFromSearchBar, updateCustomNodesDesign]);

  const getAccountAndNodeSearchingToLoad = useCallback(() => {
    let accountsToLoad: string[] = [];
    let individualNodesToLoad: string[] = [];
    const loadingOption = searchFilterOption.find((option) => option.loading === true);
    if (loadingOption === undefined) {
      searchFilterOption.forEach((option) => {
        searchFilterResult[option.label]?.forEach((record) => {
          if (option.label === 'Accounts') {
            accountsToLoad.push(record.Id);
          } else if (option.label === 'Leads') {
            if (record.ConvertedAccountId) {
              accountsToLoad.push(record.ConvertedAccountId);
            } else {
              individualNodesToLoad.push(record.Id);
            }
          } else {
            if (record.AccountId) {
              accountsToLoad.push(record.AccountId);
            } else {
              individualNodesToLoad.push(record.Id);
            }
          }
        });
      });
      accountsToLoad = accountsToLoad.filter((x) => x);
      individualNodesToLoad = individualNodesToLoad.filter((x) => x);
    }
    return { accountsToLoad, individualNodesToLoad };
  }, [searchFilterOption, searchFilterResult]);

  const getSearchingData = useCallback(
    (accountsToLoad: string[], individualNodesToLoad: string[]) => {
      getDataByVertexIdArray({
        migrationId,
        accountIdArray: accountsToLoad,
        individualNodeIdArray: individualNodesToLoad,
        filter: graphFilter,
        onSuccess: (data) => {
          setGraphSearchResult(data);
        },
      });
    },
    [getDataByVertexIdArray, graphFilter, migrationId, setGraphSearchResult],
  );

  const loadSearchingResult = useCallback(() => {
    if ([GraphRenderView.ACCOUNT, GraphRenderView.DUPLICATES].includes(graphRenderView)) {
      const { accountsToLoad, individualNodesToLoad } = getAccountAndNodeSearchingToLoad();
      if (accountsToLoad.length > 0) {
        getSearchingData(accountsToLoad, individualNodesToLoad);
      }
    } else {
      setLoading(false);
    }
  }, [getAccountAndNodeSearchingToLoad, getSearchingData, graphRenderView, setLoading]);

  useEffect(() => {
    if ([GraphRenderView.ACCOUNT, GraphRenderView.DUPLICATES].includes(graphRenderView) && searching === true) {
      loadSearchingResult();
    }
  }, [graphRenderView, loadSearchingResult, searching]);

  useEffect(() => {
    if (
      [GraphRenderView.ACCOUNT, GraphRenderView.DUPLICATES].includes(graphRenderView) &&
      graphSearchResult &&
      graphSearchResult.paths &&
      searching
    ) {
      setLoading(true);
      const { nodes } = handleGraphComponentsLoad(
        graphSearchResult.paths,
        graphSearchResult.accounts,
        graphSearchResult.individualNodes || [],
        {
          hideExtendedNodes: false,
          nodePostProcessing: {
            filterFn: HideEventRelatedPostProcessing.nodeFilterFn,
            postProcessingFn: HideEventRelatedPostProcessing.nodePostProcessingFn,
          },
          edgePostProcessing: {
            filterFn: HideEventRelatedPostProcessing.edgeFilterFn,
            postProcessingFn: HideEventRelatedPostProcessing.edgePostProcessingFn,
          },
        },
      );
      const cleanNodeIds: string[] = [];
      Object.keys(nodes).forEach((nodeId) => {
        cleanNodeIds.push(nodeId.split('.')[1]);
      });
      setTimeout(() => {
        setLoading(false);
        checkDeduplicationResultByIds(cleanNodeIds);
      }, 0);
    }
  }, [
    checkDeduplicationResultByIds,
    graphRenderView,
    graphSearchResult,
    handleGraphComponentsLoad,
    searching,
    setLoading,
  ]);

  const loadOpenLead = useCallback(() => {
    getOpenLeadsApi(migrationId, {
      limit: 50,
      offset: 0,
      projectionValues: ['FirstName', 'LastName'],
    }).then((res) => {
      setGraphOpenLeadResult(res || []);
    });
  }, [migrationId, setGraphOpenLeadResult]);

  const loadOpenLeadCounter = useCallback(() => {
    getMigrationTableDataByIdApi(migrationId, 'Lead', 0, 50, [], [], 'count').then((data: any) => {
      const counter = data[0].count;
      setGraphOpenLeadResultCounter(counter);
    });
  }, [migrationId, setGraphOpenLeadResultCounter]);

  useEffect(() => {
    if (
      migrationId &&
      neptuneMigrationStatus &&
      Object.values(MIGRATION_STATUS_END_STATE).includes(neptuneMigrationStatus.status as MIGRATION_STATUS_END_STATE)
    ) {
      loadOpenLead();
      loadOpenLeadCounter();
    }
  }, [loadOpenLead, loadOpenLeadCounter, migrationId, neptuneMigrationStatus]);

  useEffect(() => {
    if (
      [GraphRenderView.ACCOUNT, GraphRenderView.DUPLICATES].includes(graphRenderView) &&
      graphOpenLeadResultCounter &&
      schemaName &&
      (!graphFilter.lead || !graphFilter.lead.where || graphFilter.lead?.where.length === 0)
    ) {
      const id = 'open-leads';
      const label = `bridge-dummy-node-open-lead`;
      const data = {
        connectionsCount: graphOpenLeadResultCounter || 0,
        bridgeLabel: 'Open Leads',
      };
      const properties = { position: { x: 0, y: 0 }, type: BridgeNodeTypeEnum.LeadOpenNode, hidden: false };
      const dummyNode = new BridgeNode(id, label, { properties, data });

      addComponentsToGraph([], [], [dummyNode]);
    }
  }, [
    addComponentsToGraph,
    graphFilter.lead,
    graphOpenLeadResult,
    graphOpenLeadResultCounter,
    graphRenderView,
    schemaName,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateOpenLeadNodePosition = useCallback(
    debounce((containerRef: any, reactFlow: any, setNodes: any, viewX: any, viewY: any, viewZoom: any) => {
      const nodes = reactFlow.getNodes();
      const openLeadBridgeNode = reactFlow.getNode('open-leads');
      if (openLeadBridgeNode) {
        const openLeadNode: any = _.cloneDeep(openLeadBridgeNode);
        const width = containerRef?.current?.offsetWidth || 0;
        const height = containerRef?.current?.offsetHeight || 0;
        const zoom = viewZoom < 1 ? 1 : viewZoom;
        const calculatedX = ((viewX - width + width * 0.07 * zoom) * -1) / viewZoom;
        const calculatedY = ((viewY - height + width * 0.07 * zoom) * -1) / viewZoom;
        const { x, y } = openLeadNode.positionAbsolute;
        if (x !== calculatedX && y !== calculatedY) {
          openLeadNode.position = { x: calculatedX, y: calculatedY };
          const openLeadNodePrev = _.cloneDeep(nodes);
          const index = openLeadNodePrev.findIndex((node: any) => node.id === 'open-leads');
          if (index !== -1) {
            openLeadNodePrev[index] = openLeadNode;
            setNodes(openLeadNodePrev);
          }
        }
      }
    }, 30),
    [],
  );

  useEffect(() => {
    updateOpenLeadNodePosition(containerRef, reactFlow, setNodes, viewX, viewY, viewZoom);
  }, [containerRef, reactFlow, setNodes, updateOpenLeadNodePosition, viewX, viewY, viewZoom]);

  return { loadOpenLead, loadSearchingResult };
};

export default useGeneralViewEffects;
