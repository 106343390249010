import { FC, useState } from 'react';
import { Box, Container } from '@mui/material';

import { HomePage } from './pages/HomePage';
import DashboardPage from './pages/DashboardPage/DashboardPage';

const pagesCount = 2;
export const Admin: FC = () => {
  const [page, setPage] = useState(0);

  const handleNextTab = () => {
    const nextPage = (page + 1) % pagesCount;
    setPage(nextPage);
  };

  const tabs = [<HomePage key={'home'} onClickButton={handleNextTab} />, <DashboardPage key={'dashboard'} />];

  return (
    <Box
      sx={{
        flexGrow: 1,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Container fixed>{tabs[page]}</Container>
    </Box>
  );
};

export default Admin;
