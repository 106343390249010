export enum FunctionValueEnum {
  getYear = 'getYear',
  getMonth = 'getMonth',
  getDay = 'getDay',
}

export interface FunctionValue {
  function: FunctionValueEnum;
  field: string;
  label?: string;
}

export interface Condition {
  field: string | FunctionValue;
  operator: string;
  value: any;
}
