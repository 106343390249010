import { useState } from 'react';
import {
  Box,
  Drawer,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Divider,
  Tooltip,
  IconButton,
  Typography,
  ClickAwayListener,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CustomStepIcon from './CustomStepIcon';
import IntegrationActivityDataGrid from './IntegrationActivityDataGrid';
import { mockedData } from './integrationActivityUtils';

interface IntegrationActivityDrawerProps {
  open: boolean;
  onClose: () => void;
}

const steps = Array(3).fill(0);

const IntegrationActivityDrawer = ({ open, onClose }: IntegrationActivityDrawerProps) => {
  const [activeStep, setActiveStep] = useState(0);

  return (
    <Drawer open={open} elevation={0} anchor="right">
      <ClickAwayListener onClickAway={onClose}>
        <Box px={3} pb={3} pt={10} width="40vw">
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h2" fontSize={24}>
              Integration Activity
            </Typography>
            <Tooltip arrow title="Close">
              <IconButton sx={{ transform: 'scale(.9)' }} onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Divider sx={{ my: 2 }} />
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((_, index) => (
              <Step key={index}>
                <StepLabel
                  sx={{ cursor: 'pointer' }}
                  StepIconComponent={CustomStepIcon}
                  onClick={() => setActiveStep(index)}
                >
                  <Box
                    padding={1}
                    borderRadius={1}
                    color="#1554FF"
                    border="1px solid #EDF0F5"
                    sx={{ cursor: 'pointer' }}
                  >
                    {index === 0 ? 'TODAY' : `MAR ${index + 1}`}
                  </Box>
                </StepLabel>
                <StepContent>
                  <Box height={275}>
                    <IntegrationActivityDataGrid data={mockedData} />
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </Box>
      </ClickAwayListener>
    </Drawer>
  );
};

export default IntegrationActivityDrawer;
