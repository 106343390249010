import React, { useRef, useState } from 'react';
import { Box, Button, Menu, MenuItem, Typography, Grid, Divider } from '@mui/material';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TextRotateUpIcon from '@mui/icons-material/TextRotateUp';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import TextRotationDownIcon from '@mui/icons-material/TextRotationDown';

import { Tooltip } from './Tooltip';
import { Renderer } from './Renderer';

import ScoreFilterButtons from './ScoreFilterButtons';
import useResizeObserver from './useResizeObserver';

export type InteractionData = {
  companyName: string;
  revenue: string;
  accountOwner: string;
  lastContactDate: string;
  leads: number;
  opportunities: number;
  contacts: number;
  confidenceScore: number;
  xLabel: string;
  yLabel: string;
  xPos: number;
  yPos: number;
};

type DataHealthTabPanelProps = {
  numRecords: number;
  elements: {
    companyName: string;
    revenue: string;
    accountOwner: string;
    lastContactDate: string;
    leads: number;
    opportunities: number;
    contacts: number;
    confidenceScore: number;
  }[];
};

const options = [
  { name: 'Last Added', icon: AccessTimeIcon },
  { name: 'Last Updated', icon: CalendarTodayIcon },
  { name: 'Alphabetical Dsc. (Z-A)', icon: TextRotateUpIcon },
  { name: 'Alphabetical Asc. (A-Z)', icon: TextRotationDownIcon },
];

const DataHealthTabPanel: React.FC<DataHealthTabPanelProps> = ({ numRecords, elements }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedButton, setSelectedButton] = useState(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [hoveredCell, setHoveredCell] = useState<InteractionData | null>(null);

  const containerRef = useRef(null);
  const { width: parentWidth } = useResizeObserver(containerRef);

  const handleClickItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1, mt: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mr: 4 }}>
          <Typography fontSize={12} sx={{ color: '#898EA1' }}>
            Order by:{' '}
          </Typography>
          <Button
            variant="text"
            aria-controls={anchorEl ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={anchorEl ? 'true' : undefined}
            sx={{
              color: 'black',
              fontSize: '12px',
              '&:hover': {
                backgroundColor: 'initial',
                boxShadow: 'none',
                color: 'black',
              },
            }}
            onClick={handleClickItem}
            endIcon={anchorEl ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          >
            {options[selectedIndex].name}
          </Button>
          <Menu
            id="demo-customized-menu"
            MenuListProps={{
              'aria-labelledby': 'demo-customized-button',
            }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            {options.map((option, index) => (
              <MenuItem
                key={option.name}
                selected={index === selectedIndex}
                onClick={(event) => handleMenuItemClick(event, index)}
                sx={{ marginBottom: '5px' }}
              >
                <option.icon sx={{ marginRight: '12px' }} />
                {option.name}
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography fontSize={12} sx={{ color: '#898EA1' }}>
            Assigned to:{' '}
          </Typography>
          <Button
            variant="text"
            sx={{
              color: 'black',
              fontSize: '12px',

              '&:hover': {
                backgroundColor: 'initial',
                boxShadow: 'none',
                color: 'black',
              },
            }}
            endIcon={<KeyboardArrowDownIcon />}
          >
            John Doe (me)
          </Button>
        </Box>
      </Box>
      <Grid container alignItems="center">
        <Grid item>
          <Typography sx={{ color: '#898EA1', paddingRight: 1, fontSize: '10px' }}>
            {options[selectedIndex].name}
          </Typography>
        </Grid>
        <Grid item xs>
          <Box sx={{ width: '100%' }}>
            <Divider
              sx={{
                borderStyle: 'dashed',
                borderWidth: '1px 0 0 0',
                borderColor: 'rgba(0, 0, 0, 0.12)',
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Box position={'relative'} ref={containerRef} width="100%">
        <Renderer width={parentWidth} elements={elements} setHoveredCell={setHoveredCell} numCells={numRecords} />
        <Tooltip interactionData={hoveredCell} width={1400} height={400} />
      </Box>
      <ScoreFilterButtons selectedButton={selectedButton} setSelectedButton={setSelectedButton} />
    </>
  );
};
export default DataHealthTabPanel;
