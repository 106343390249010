import { combineReducers } from '@reduxjs/toolkit';

import accountReducer, { initialState as accountState } from './account/reducers';
import activityReducer, { initialState as activityState } from './activity/reducers';
import authReducer, { initialState as authState } from './auth/reducers';
import contactReducer, { initialState as contactState } from './contact/reducers';
import dataraptorReducer, { initialState as dataraptorState } from './dataRaptor/reducers';
import dataRaptorRuleReducer, { initialState as dataRaptorRuleState } from './dataRaptorRule/reducers';
import dataSourceReducer, { initialState as dataSourceState } from './dataSource/reducers';
import dealReducer, { initialState as dealState } from './deal/reducers';
import dealStageReducer, { initialState as dealStageState } from './dealStage/reducers';
import emailReducer, { initialState as emailState } from './email/reducers';
import integrationStatusReducer, { initialState as integrationStatusState } from './integration-status/reducers';
import integrationReducer, { initialState as integrationState } from './integration/reducers';
import migrationReducer, { initialState as migrationState } from './migration/reducers';
import tenantReducer, { initialState as tenantState } from './tenant/reducers';
import userReducer, { initialState as userState } from './user/reducers';
import socketReducer, { initialState as socketState } from './socket/reducers';
import duplicateDetectionReducer, { initialState as duplicateDetectionState } from './duplicateDetection/reducers';
import ruleFormReducer, { initialState as ruleFormInitialState } from './ruleForm/reducers';
import chatReducer, { initialState as chatState } from './chat/reducers';
import { threadReducer, threadState } from './chat/reducers';
import graphDataReducer, { initialState as graphDataInitialState } from './graphData/reducers';
import graphRenderReducer, { initialState as graphRenderInitialState } from './graphRender/reducers';
import ruleLibraryReducer, { initialState as ruleLibraryInitialState } from './ruleLibrary/reducers';
import deduplicationConfigReducer, {
  initialState as deduplicationConfigInitialState,
} from './deduplicationConfig/reducers';
import deduplicationResultReducer, { initialState as deduplicationResultState } from './deduplicationResult/reducers';
import dataAnomalyReducer, { initialState as dataAnomalyState } from './dataAnomaly/reducers';
import createTaskFormReducer, { initialState as createTaskFormReducerState } from './createTaskForm/reducers';
import temporalTableFormReducer, { initialState as temporalTableFormState } from './temporalTableForm/reducers';

import { backendApi } from 'store/api';

export const initialRootState = {
  auth: authState,
  user: userState,
  contact: contactState,
  account: accountState,
  deal: dealState,
  email: emailState,
  dealStage: dealStageState,
  tenant: tenantState,
  integration: integrationState,
  integrationStatus: integrationStatusState,
  activity: activityState,
  dataSource: dataSourceState,
  migration: migrationState,
  dataRaptor: dataraptorState,
  dataRaptorRule: dataRaptorRuleState,
  socket: socketState,
  duplicateDetection: duplicateDetectionState,
  ruleForm: ruleFormInitialState,
  chat: chatState,
  thread: threadState,
  graphData: graphDataInitialState,
  graphRender: graphRenderInitialState,
  ruleLibrary: ruleLibraryInitialState,
  deduplicationConfig: deduplicationConfigInitialState,
  deduplicationResult: deduplicationResultState,
  dataAnomaly: dataAnomalyState,
  createTaskForm: createTaskFormReducerState,
  temporalTableForm: temporalTableFormState,
};

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  contact: contactReducer,
  account: accountReducer,
  deal: dealReducer,
  email: emailReducer,
  dealStage: dealStageReducer,
  tenant: tenantReducer,
  integration: integrationReducer,
  integrationStatus: integrationStatusReducer,
  activity: activityReducer,
  dataRaptorRule: dataRaptorRuleReducer,
  dataSource: dataSourceReducer,
  migration: migrationReducer,
  dataRaptor: dataraptorReducer,
  socket: socketReducer,
  duplicateDetection: duplicateDetectionReducer,
  ruleForm: ruleFormReducer,
  chat: chatReducer,
  thread: threadReducer,
  graphData: graphDataReducer,
  graphRender: graphRenderReducer,
  ruleLibrary: ruleLibraryReducer,
  deduplicationConfig: deduplicationConfigReducer,
  deduplicationResult: deduplicationResultReducer,
  dataAnomaly: dataAnomalyReducer,
  createTaskForm: createTaskFormReducer,
  temporalTableForm: temporalTableFormReducer,
  [backendApi.reducerPath]: backendApi.reducer,
});

export default rootReducer;
