import { useEffect, useRef, useState } from 'react';
import { Box, Chip, Link, Switch, SwitchProps, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { faker } from '@faker-js/faker';

import { ColumnSortedAscendingIcon, ColumnSortedDescendingIcon, ColumnUnsortedIcon } from 'pages/DataRaptor/ui';

const SwitchCustom = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 21,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 17,
  },
  '& .MuiSwitch-track': {
    borderRadius: 21 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const dataRows = Array.from({ length: 23 }, (_, index) => ({
  id: index + 1,
  account: faker.person.firstName(),
  dateUpdated: 'Updated ' + faker.date.month({ abbreviated: true }) + ' 23, 2024, ' + faker.person.firstName(),
  value: faker.number.int({ min: -100, max: 100 }),
}));

export interface ActiveRulesAndScoreImpactData {
  id: number;
  account: string;
  dateUpdated: string;
  value: number;
}

function getColor(minValue: number, mediunValue: number, maxValue: number, value: number) {
  if (minValue <= value && mediunValue > value) {
    return '#FB4E6D';
  } else if (mediunValue <= value && maxValue > value) {
    return '#FF9960';
  } else if (maxValue <= value) {
    return '#60C67C';
  }

  return 'white';
}

const getActiveRulesColumns: (width: number) => GridColumns<ActiveRulesAndScoreImpactData> = (width) => [
  {
    width: 0.3 * width,
    field: 'Account',
    renderCell: ({ row }) => (
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <SwitchCustom sx={{ m: 1 }} defaultChecked />
        <Tooltip title={'View Rule'}>
          <Link fontSize={14} underline="none">
            {row.account}
          </Link>
        </Tooltip>
      </Box>
    ),
  },
  {
    align: 'center',
    field: 'LastUpdated',
    width: 0.4 * width,
    renderCell: ({ row }) => (
      <Typography fontSize={12} color={'#898EA1'}>
        {row.dateUpdated}
      </Typography>
    ),
  },
  {
    align: 'right',
    width: 0.3 * width,
    field: 'Values',
    renderCell: ({ row }) => (
      <Chip
        size="small"
        label={row.value}
        sx={{
          color: 'white',
          backgroundColor: getColor(-100, 0, 50, row.value),
        }}
      />
    ),
  },
];

const pageSize = 3;

export default function ActiveRulesTable() {
  const ref = useRef<HTMLDivElement>();
  const [columns, setColumns] = useState(getActiveRulesColumns(0));

  const handleResize = () => {
    const width = ref.current?.clientWidth ?? 100;
    setColumns(getActiveRulesColumns(width));
  };

  useEffect(() => {
    handleResize();
    ref.current?.addEventListener('resize', handleResize);
    return () => {
      ref.current?.removeEventListener('resize', handleResize);
    };
  }, [ref.current]);

  return (
    <Box ref={ref} width={'100%'} height={'100%'}>
      <DataGrid
        disableColumnFilter
        disableColumnSelector
        disableSelectionOnClick
        rows={dataRows}
        columns={[...columns]}
        rowHeight={56}
        headerHeight={0}
        rowsPerPageOptions={[pageSize]}
        pageSize={pageSize}
        getRowId={(r) => r.id}
        density="comfortable"
        components={{
          ColumnUnsortedIcon,
          ColumnSortedAscendingIcon,
          ColumnSortedDescendingIcon,
        }}
      />
    </Box>
  );
}
