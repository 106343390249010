import { Box, Button, CircularProgress, IconButton, Modal, Typography, InputLabel } from '@mui/material';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import theme from 'core/theme';
import { FC, useCallback, useEffect, useState } from 'react';
import { PrimaryButton } from 'components/ui';
import { CustomInput } from 'components/CustomInput';
import { Textarea } from 'components/CustomTextarea/ui';
import { SummaryBox } from './components/SummaryBox';
import { MigrationFieldValuesDropDown } from '../FilterPanel/components/InputFields/MigrationFieldValuesDropDown';
import { MigrationFieldValueTableDropDown } from 'components/MigrationComponents/MigrationFieldValueTableDropDown';
import { useCreateTaskFormStore } from 'store/createTaskForm/hooks';
import { getTaskForDataAnomalyCompletion } from 'http/ai/index';
import { createRecordOnMigration } from 'http/migration/index';
import { RuleComponentType } from 'store/dataRaptorRule/dto/Enums';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';

export interface CreateActivityModalProps {
  open: boolean;
  migrationId: string;
  handleCloseMergeModal: () => void;
}

const validationSchema = yup.object({
  subject: yup.string().required('Subject is required'),
  notes: yup.string().required('Notes is required'),
  dueDate: yup
    .date()
    .min(new Date().toISOString().split('T')[0])
    .max(
      new Date(new Date().getFullYear() + 100, new Date().getMonth(), new Date().getDate()).toISOString().split('T')[0],
    )
    .required('Due Date is required'),
  status: yup.string().required('Status is required'),
  assignTo: yup.string().required('Assign to is required'),
  taskType: yup.string().required('Task Type is required'),
});

export const CreateActivityModal: FC<CreateActivityModalProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string>('');
  const { open, handleCloseMergeModal, migrationId } = props;
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: { aiDetail, assignTo, dueDate, notes, status, subject, taskType },
    requestData: { details, record, tableName, recordId },
    setSubject,
    setNotes,
    setDueDate,
    setStatus,
    setAssignTo,
    setTaskType,
    reset,
  } = useCreateTaskFormStore();

  const handleClose = useCallback(() => {
    reset();
    handleCloseMergeModal();
  }, [handleCloseMergeModal, reset]);

  const handleCreateTaskRecord = useCallback(() => {
    if (migrationId) {
      const formFields: any = {
        subject,
        notes,
        dueDate,
        status,
        taskType,
        assignTo,
      };

      validationSchema
        .validate(formFields)
        .then(() => {
          setLoading(true);
          setLoadingMessage('Creating Task...');

          const taskRecord: any = {
            Subject: subject,
            Description: notes,
            ActivityDate: dueDate,
            Status: status,
            Type: taskType,
            OwnerId: assignTo,
          };

          if (['lead', 'contact', 'user'].includes((tableName || '').toLocaleLowerCase())) {
            taskRecord['WhoId'] = recordId;
          } else {
            taskRecord['WhatId'] = recordId;
          }
          createRecordOnMigration(migrationId, 'Task', taskRecord)
            .then(() => {
              handleClose();
              enqueueSnackbar('Task Created!', { variant: 'success' });
            })
            .catch(() => {
              enqueueSnackbar('Error creating task', { variant: 'error' });
            })
            .finally(() => {
              setLoading(false);
              setLoadingMessage('');
            });
        })
        .catch((error) => {
          enqueueSnackbar(error.message, { variant: 'error' });
          setLoading(false);
          setLoadingMessage('');
        });
    }
  }, [
    assignTo,
    dueDate,
    enqueueSnackbar,
    handleClose,
    migrationId,
    notes,
    recordId,
    status,
    subject,
    tableName,
    taskType,
  ]);

  useEffect(() => {
    if (migrationId && details && record && tableName) {
      setLoading(true);
      setLoadingMessage('Loading Task Details...');
      getTaskForDataAnomalyCompletion(migrationId, { details: details, record, tableName })
        .then((response) => {
          const { dueDate, notes, subject } = response?.completion || {};
          if (subject) {
            setSubject(subject);
          }
          if (notes) {
            setNotes(notes);
          }
          if (dueDate) {
            const date = dueDate.split('T')[0];
            setDueDate(date);
          }
        })
        .finally(() => {
          setLoading(false);
          setLoadingMessage('');
        });
    }
  }, [details, migrationId, record, tableName, setDueDate, setNotes, setSubject]);

  return (
    <Modal
      sx={{ p: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      open={open}
      onClose={handleClose}
    >
      <Box
        sx={{
          maxHeight: '90%',
          maxWidth: '90%',
          width: '100%',
          backgroundColor: theme.palette.neutral.white,
          borderRadius: '4px',
          padding: 3,
        }}
      >
        {/* Title */}
        <Box pb={2} display={'flex'} justifyContent={'space-between'}>
          <Typography sx={{ fontWeight: 'bold' }} variant="h3">
            Create Task
          </Typography>
          <IconButton onClick={handleClose}>
            <CrossIcon />
          </IconButton>
        </Box>

        {loading == true && (
          <Box sx={{ textAlign: 'center' }}>
            <CircularProgress></CircularProgress>
            <Typography>{loadingMessage}</Typography>
          </Box>
        )}

        {loading != true && (
          <>
            {aiDetail && (
              <Box sx={{ mb: 2 }}>
                <SummaryBox value={aiDetail || ''} />
              </Box>
            )}
            {/* Form */}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {/* Subject */}
              <Box sx={{ width: '100%' }}>
                <InputLabel htmlFor={'subject'} sx={{ marginBottom: 1 }}>
                  Subject
                </InputLabel>
                <Textarea
                  id={'subject'}
                  name={'subject'}
                  sx={{ width: '100%', minHeight: '40px' }}
                  value={subject}
                  onChange={(event) => setSubject(event.target.value)}
                />
              </Box>

              {/* Notes */}
              <Box sx={{ width: '100%' }}>
                <InputLabel htmlFor={'notes'} sx={{ marginBottom: 1 }}>
                  Notes
                </InputLabel>
                <Textarea
                  id={'notes'}
                  name={'notes'}
                  sx={{ width: '100%', minHeight: '66px' }}
                  value={notes}
                  onChange={(event) => setNotes(event.target.value)}
                />
              </Box>

              {/* Type and Date */}
              <Box sx={{ display: 'flex', gap: 4 }}>
                <Box flex={1}>
                  <MigrationFieldValuesDropDown
                    label={'Task Type'}
                    id={'taskType'}
                    value={taskType || ''}
                    migrationId={migrationId}
                    table={'Task'}
                    fieldName={'Type'}
                    placeholder={'Select Type'}
                    onChange={(value) => setTaskType(value)}
                  />
                </Box>
                <Box flex={1}>
                  <CustomInput
                    id={'dueDate'}
                    name={'dueDate'}
                    label={'Due Date'}
                    type={'date'}
                    value={dueDate?.toString()}
                    onChange={(event) => {
                      setDueDate(event.target.value);
                      console.log('due date', event.target.value);
                    }}
                  />
                </Box>
              </Box>

              {/* Assign and status */}
              <Box sx={{ display: 'flex', gap: 4 }}>
                <Box flex={1}>
                  <MigrationFieldValueTableDropDown
                    id={'assignTo'}
                    value={assignTo || ''}
                    label="Assign to"
                    placeholder={'Select User'}
                    migrationId={migrationId}
                    table={'User'}
                    optionValueFieldName={'Id'}
                    optionLabelFieldNames={['Name', 'Email']}
                    onChange={(value) => setAssignTo(value)}
                    conditions={[
                      { field: 'IsActive', operator: 'is', value: 'true', type: RuleComponentType.ROOT_CONDITIONAL },
                      {
                        field: 'UserType',
                        operator: '=',
                        value: "'Standard'",
                        type: RuleComponentType.ROOT_CONDITIONAL,
                      },
                    ]}
                  />
                </Box>
                <Box flex={1}>
                  <MigrationFieldValuesDropDown
                    label={'Status'}
                    id={'status'}
                    value={status || ''}
                    migrationId={migrationId}
                    table={'Task'}
                    fieldName={'Status'}
                    placeholder={'Select Status'}
                    onChange={(value: string) => setStatus(value)}
                  />
                </Box>
              </Box>
            </Box>
          </>
        )}

        {/* Footer */}
        <Box display={'flex'} justifyContent={'end'} flexWrap={'nowrap'} gap={1.5} sx={{ p: '1rem' }}>
          <Button variant="text" disabled={loading == true} sx={{ color: 'black' }} onClick={handleClose}>
            Cancel
          </Button>
          <PrimaryButton disabled={loading == true} onClick={handleCreateTaskRecord}>
            Create Task
          </PrimaryButton>
        </Box>
      </Box>
    </Modal>
  );
};
