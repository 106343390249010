import React from 'react';
import { faker } from '@faker-js/faker';

import { ResponsiveLine } from '@nivo/line';
import { Box, Divider, LinearProgress, Paper, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

const generateData = (legends: Legends[]) => {
  const labels = ['Sep 2022', 'Oct 2022', 'Nov 2022', 'Dec 2022', 'Jan 2023', 'Feb 2023'];
  return legends.map((legend, index) => ({
    id: legend.name,
    data: labels.map((label) => ({
      x: label,
      y: faker.number.int(100),
    })),
  }));
};

function getColor(minValue: number, mediunValue: number, maxValue: number, value: number) {
  if (minValue <= value && mediunValue > value) {
    return '#FB4E6D';
  } else if (mediunValue <= value && maxValue > value) {
    return '#FF9960';
  } else if (maxValue <= value) {
    return '#60C67C';
  }

  return 'white';
}

type Legends = {
  name: string;
  color: string;
};

interface Point {
  id: string;
  index: number;
  serieId: string | number;
  serieColor: string;
  x: number;
  y: number;
  color: string;
  borderColor: string;
  data: {
    x: string | number | Date;
    xFormatted: string | number;
    y: string | number | Date;
    yFormatted: string | number;
    yStacked?: number;
  };
}

type CustomItemTooltipContentProps = {
  point: {
    point: Point;
  };
};

const CustomItemTooltipContent: React.FC<CustomItemTooltipContentProps> = ({ point }) => {
  return (
    <Paper sx={{ width: 270, padding: 2, backgroundColor: '#0d2045' }}>
      <Typography color={'white'} fontSize={12} fontWeight={600}>
        Feb 16, 2024
      </Typography>
      <Box display="flex" fontSize={12} color={'#9da1b5'} justifyContent="space-between" pt={1}>
        <Typography fontSize={12}>Accounts:</Typography>
        <Typography fontSize={12} fontWeight={600} color={'white'}>
          {point.point.data.y.toString()}
        </Typography>
      </Box>
      <Box display="flex" color="#9da1b5" justifyContent="space-between" pt={1} alignItems="center">
        <Typography fontSize={12}>Avg. Confidence Score:</Typography>
        <Box position="relative" display="inline-flex" width="80px">
          <LinearProgress
            value={60}
            variant="determinate"
            sx={{
              borderRadius: '8px',
              width: '100%',
              height: '20px',
              backgroundColor: '#F6F8FB',
              '& .MuiLinearProgress-bar': {
                backgroundColor: getColor(0, 33, 66, 60),
              },
            }}
          />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography sx={{ fontSize: '10px', color: 'black' }}>{`Average ${60}%`}</Typography>
          </Box>
        </Box>
      </Box>
      <Box display="flex" fontSize={12} color={'#9da1b5'} justifyContent="space-between" py={1}>
        <Typography fontSize={12}>vs Previous Month:</Typography>
        <Typography fontSize={12} fontWeight={600} color={'white'}>
          {point.point.data.y.toString()}
        </Typography>
      </Box>
      <Divider sx={{ backgroundColor: '#9da1b5' }} />
      <Box display="flex" alignItems="center" pt={1} gap={1}>
        <ErrorIcon fontSize="small" sx={{ color: '#9da1b5' }} />
        <Typography color="#9da1b5" fontSize={12}>
          Click an object to view record details
        </Typography>
      </Box>
    </Paper>
  );
};

export const TimeLinearChart: React.FC<TimeLinearChartProps> = ({ legends }) => {
  const data = generateData(legends);

  return (
    <ResponsiveLine
      data={data}
      colors={legends.map((legend) => legend.color)}
      margin={{ right: 25, bottom: 22, left: 36, top: 30 }}
      enableGridX={false}
      enableGridY={false}
      useMesh={true}
      yScale={{
        max: 100,
        type: 'linear',
      }}
      axisLeft={{
        tickValues: [0, 25, 50, 75, 100],
        format: (value) => `${value}%`,
      }}
      tooltip={(point) => <CustomItemTooltipContent point={point} />}
    />
  );
};

type TimeLinearChartProps = {
  legends: Legends[];
};
