import { useEffect, useRef, useState } from 'react';

import * as d3 from 'd3';
import { zoom } from 'd3-zoom';

import ZoomControls from './ZoomControls';
import { InteractionData } from './DataHealthPanel';

const MARGIN = { top: 10, right: 0, bottom: 10, left: 0 };

type RendererProps = {
  setHoveredCell: (hoveredCell: InteractionData | null) => void;
  numCells: number;
  width: number;
  elements: {
    companyName: string;
    revenue: string;
    accountOwner: string;
    lastContactDate: string;
    leads: number;
    opportunities: number;
    contacts: number;
    confidenceScore: number;
  }[];
};

type HeatmapData = {
  companyName: string;
  revenue: string;
  accountOwner: string;
  lastContactDate: string;
  leads: number;
  opportunities: number;
  contacts: number;
  confidenceScore: number;
  x: string;
  y: string;
}[];

export const Renderer = ({ setHoveredCell, numCells, elements, width }: RendererProps) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const boundsRef = useRef<SVGGElement>(null);
  const [zoomTransform, setZoomTransform] = useState<d3.ZoomTransform>(d3.zoomIdentity);
  const [isExpanded, setIsExpanded] = useState(false);
  const [numRows, setNumRows] = useState(5);
  const [boundsHeight, setBoundsHeight] = useState(260);
  const numCols = 45;
  const cellSize = 24;

  useEffect(() => {
    const calculateDimensions = () => {
      console.log(numCells);
      if (numCells <= 450) {
        setNumRows(Math.ceil(numCells / 45));
      } else {
        setNumRows(10);
      }
      setBoundsHeight(260);
    };

    calculateDimensions();
  }, [numCells]);

  const boundsCellsWidth = numCols * cellSize;
  const boundsCellsHeight = numRows * cellSize;

  const allYGroups = Array.from(new Set(Array.from({ length: numRows }, (_, i) => 'cont' + i)));
  const allXGroups = Array.from(new Set(Array.from({ length: numCols }, (_, i) => 'cont' + i)));

  const xScale = d3.scaleBand().domain(allXGroups).range([0, boundsCellsWidth]).padding(0.1);
  const yScale = d3.scaleBand().domain(allYGroups).range([0, boundsCellsHeight]).padding(0.1);

  useEffect(() => {
    if (!svgRef.current) return;

    const svgElement = d3.select<SVGSVGElement, unknown>(svgRef.current);
    const initialTransform = d3.zoomIdentity.translate(MARGIN.left, MARGIN.top).scale(zoomTransform.k);

    const zoomHandler = zoom<SVGSVGElement, unknown>()
      .scaleExtent([1, 8])
      .on('zoom', (event) => {
        if (!boundsRef.current) return;
        const { transform } = event;
        const { x, y, k } = transform;

        const maxX = Math.max(0, boundsCellsWidth - width + boundsCellsWidth * k - boundsCellsWidth);
        const maxY = Math.max(0, boundsCellsHeight - boundsHeight + boundsCellsHeight * k - boundsCellsHeight);
        const clampedX = Math.min(0, Math.max(-maxX, x));
        const clampedY = Math.min(0, Math.max(-maxY, y));

        const clampedTransform = d3.zoomIdentity.translate(clampedX, clampedY).scale(k);
        setZoomTransform(clampedTransform);
        boundsRef.current.setAttribute('transform', clampedTransform.toString());
      })
      .filter((event) => !event.ctrlKey && event.type !== 'wheel');

    if (svgElement) {
      svgElement.call(zoomHandler.transform, initialTransform);
      svgElement.call(zoomHandler as any);
    }
  }, [zoomTransform.k, width, boundsHeight]);

  useEffect(() => {
    setData(() => {
      return elements.map((element, index) => ({
        ...element,
        x: 'cont' + (index % numCols),
        y: 'cont' + Math.floor(index / numCols),
      }));
    });
  }, [numCols, numRows]);

  const [data, setData] = useState<HeatmapData>(() => {
    const limitedElements = elements.slice(0, 450);
    return limitedElements.map((element, index) => ({
      ...element,
      x: 'cont' + (index % numCols),
      y: 'cont' + Math.floor(index / numCols),
    }));
  });

  const handleZoom = (comp: (...values: number[]) => number, zoom: number, limit: number) => {
    setZoomTransform((prevZoomTransform) => {
      const newZoomLevel = comp(prevZoomTransform.k * zoom, limit);
      const newZoomTransform = prevZoomTransform.scale(newZoomLevel / prevZoomTransform.k);
      boundsRef.current?.setAttribute('transform', newZoomTransform.toString());
      return newZoomTransform;
    });
  };

  const handleZoomIn = () => {
    handleZoom(Math.min, 1.2, 8);
  };

  const handleZoomOut = () => {
    handleZoom(Math.max, 0.8, 1);
  };

  const handleResetZoom = () => {
    setZoomTransform(d3.zoomIdentity);
    if (boundsRef.current) {
      boundsRef.current.setAttribute('transform', d3.zoomIdentity.toString());
    }
  };

  const handleExpand = () => {
    if (!isExpanded) {
      const newNumRows = Math.ceil(numCells / numCols);
      setNumRows(newNumRows);
      if (newNumRows > 30) {
        setBoundsHeight(30 * 24);
      } else {
        setBoundsHeight(newNumRows * 24);
      }
      setIsExpanded(true);
    } else {
      setNumRows(10);
      setBoundsHeight(10 * 24);
      setIsExpanded(false);
    }
  };

  const colorScale = d3.scaleThreshold<number, string>().domain([33, 66, 100]).range(['#FB4E6D', '#FF9960', '#60C67C']);

  const allShapes = data.map((d, i) => {
    const x = xScale(d.x);
    const y = yScale(d.y);

    if (d.confidenceScore === null || !x || !y) {
      return null;
    }

    return (
      <rect
        key={i}
        x={x}
        y={y}
        width={cellSize}
        height={cellSize}
        opacity={1}
        fill={colorScale(d.confidenceScore)}
        rx={6}
        stroke={'white'}
        onMouseEnter={(e) => {
          const transformedX = (x + 15) * zoomTransform.k + cellSize / 2 + zoomTransform.x + MARGIN.left;
          const transformedY = (y + 15) * zoomTransform.k + cellSize / 2 + zoomTransform.y + MARGIN.top;

          setHoveredCell({
            companyName: d.companyName,
            revenue: d.revenue,
            accountOwner: d.accountOwner,
            lastContactDate: d.lastContactDate,
            leads: d.leads,
            opportunities: d.opportunities,
            contacts: d.contacts,
            confidenceScore: d.confidenceScore,
            xLabel: d.x,
            yLabel: d.y,
            xPos: transformedX,
            yPos: transformedY - 30,
          });
        }}
        onMouseLeave={() => setHoveredCell(null)}
        cursor="pointer"
      />
    );
  });

  return (
    <div>
      <svg ref={svgRef} width={width} height={boundsHeight + MARGIN.top + MARGIN.bottom}>
        <g ref={boundsRef} transform={`translate(${MARGIN.left},${MARGIN.top})`}>
          {allShapes}
        </g>
      </svg>
      <ZoomControls
        zoom={zoomTransform.k}
        handleExpand={handleExpand}
        handleZoomIn={handleZoomIn}
        handleZoomOut={handleZoomOut}
        handleResetZoom={handleResetZoom}
      />
    </div>
  );
};
