import { SearchDropdown } from 'components/SearchDropdown';
import { OptionValue } from 'core/types';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRuleLibrary } from 'store/ruleLibrary/hook';

const SearchRuleDropDown: FC = () => {
  const {
    selectedSubPage,
    subPages: {
      library: {
        data: { filteredRules },
      },
      template: {
        data: { filteredTemplates },
      },
      temporalTable: {
        data: { tables: filteredTempTables },
      },
    },
    setRenderedRule,
    setRenderedTemplates,
    setRenderedTempTables,
  } = useRuleLibrary();

  useEffect(() => {
    if (selectedSubPage) {
      setRenderedRule(filteredRules);
      setRenderedTemplates(filteredTemplates);
      setRenderedTempTables(filteredTempTables);
    }
  }, [
    filteredRules,
    filteredTempTables,
    filteredTemplates,
    selectedSubPage,
    setRenderedRule,
    setRenderedTempTables,
    setRenderedTemplates,
  ]);

  const options: OptionValue<string>[] = useMemo(() => {
    let options = [];
    if (selectedSubPage === 'library') {
      options = filteredRules.map((rule) => ({ label: rule.name, value: rule.ruleId }));
    } else if (selectedSubPage === 'template') {
      options = filteredTemplates.map((template) => ({ label: template.name, value: template.ruleTemplateId }));
    } else {
      options = filteredTempTables.map((table) => ({ label: table.name, value: table.ruleTempTableId }));
    }
    return options;
  }, [filteredRules, filteredTempTables, filteredTemplates, selectedSubPage]);

  const barPlaceHolder = useMemo(() => {
    if (selectedSubPage === 'library') {
      return 'Find a Rule';
    } else if (selectedSubPage === 'template') {
      return 'Find a Rule Template';
    } else {
      return 'Find a Temporal Table';
    }
  }, [selectedSubPage]);

  const handleOnChange = useCallback(
    (value: string) => {
      if (selectedSubPage === 'library') {
        const rulesTmp = filteredRules.filter((rule) => rule.name?.match(new RegExp(value, 'i')));
        if (rulesTmp?.length > 0) {
          setRenderedRule(rulesTmp);
        } else {
          setRenderedRule(filteredRules);
        }
      } else if (selectedSubPage === 'template') {
        const templateTmp = filteredTemplates.filter((template) => template.name?.match(new RegExp(value, 'i')));
        if (templateTmp?.length > 0) {
          setRenderedTemplates(templateTmp);
        } else {
          setRenderedTemplates(filteredTemplates);
        }
      } else {
        const tableTmp = filteredTempTables.filter((table) => table.name?.match(new RegExp(value, 'i')));
        if (tableTmp?.length > 0) {
          setRenderedTempTables(tableTmp);
        } else {
          setRenderedTempTables(filteredTempTables);
        }
      }
    },
    [
      selectedSubPage,
      filteredRules,
      filteredTemplates,
      filteredTempTables,
      setRenderedTemplates,
      setRenderedRule,
      setRenderedTempTables,
    ],
  );

  const handleOnSelect = useCallback(
    (id: string) => {
      if (selectedSubPage === 'library') {
        const rulesTmp = filteredRules.find((rule) => rule.ruleId === id);
        if (rulesTmp) {
          setRenderedRule([rulesTmp]);
        } else {
          setRenderedRule(filteredRules);
        }
      } else if (selectedSubPage === 'template') {
        const templateTmp = filteredTemplates.find((template) => template.ruleTemplateId === id);
        if (templateTmp) {
          setRenderedTemplates([templateTmp]);
        } else {
          setRenderedTemplates(filteredTemplates);
        }
      } else {
        const tableTmp = filteredTempTables.find((table) => table.ruleTempTableId === id);
        if (tableTmp) {
          setRenderedTempTables([tableTmp]);
        } else {
          setRenderedTempTables(filteredTempTables);
        }
      }
    },
    [
      selectedSubPage,
      filteredRules,
      setRenderedRule,
      filteredTemplates,
      setRenderedTemplates,
      filteredTempTables,
      setRenderedTempTables,
    ],
  );

  return (
    <SearchDropdown
      id="search-rule-library"
      placeholder={barPlaceHolder}
      onSelect={handleOnSelect}
      onChange={handleOnChange}
      options={options}
    />
  );
};

export { SearchRuleDropDown };
