import { useState } from 'react';
import { Box, Paper, Typography, Link } from '@mui/material';
import { mockedData } from './integrationActivityUtils';
import IntegrationActivityDataGrid from './IntegrationActivityDataGrid';
import IntegrationActivityDrawer from './IntegrationActivityDrawer';

export const IntegrationActivityTable = () => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (value: boolean) => () => setOpen(value);

  return (
    <Paper elevation={0} sx={{ padding: 3 }}>
      <Typography variant="h2" fontSize={20}>
        Integration Activity
      </Typography>
      <Box py={1} height={300}>
        <IntegrationActivityDataGrid data={mockedData} />
      </Box>
      <Link fontSize={14} onClick={toggleDrawer(true)} sx={{ cursor: 'pointer', textDecoration: 'none' }}>
        View All
      </Link>
      <IntegrationActivityDrawer open={open} onClose={toggleDrawer(false)} />
    </Paper>
  );
};
