import { Box, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import { ReactComponent as DotsIcon } from 'assets/icons/dots.svg';
import { DataAnomalyCardFieldDetail } from './components/DataAnomalyCardFieldDetail';
import { getDataAnomalySummary } from 'http/ai';

import theme from 'core/theme';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { DataRaptorRule } from 'store/dataRaptorRule/types';
import { AiRecommendation, DataAnomalyFieldDetail, DataAnomalyRecord } from 'store/dataAnomaly/types';
import { DataAnomalyCardMenu } from './components/DataAnomalyCardMenu';

export interface DataAnomalyCardProps {
  migrationId: string;
  rule: DataRaptorRule;
  anomaly: DataAnomalyRecord;
  node: any;
  aiRecommendation?: AiRecommendation;
}

export interface DataAnomalyFieldDetailDescriptionMerged {
  field: string;
  value: string;
  description: string[];
}

export const DataAnomalyCard: FC<DataAnomalyCardProps> = (props) => {
  const { rule, anomaly, node, migrationId, aiRecommendation } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [summary, setSummary] = useState<string>();
  const [fieldRecommendation, setFieldRecommendation] = useState<{ [fieldName: string]: string }>({});
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleGetAnomalySummary = useCallback(() => {
    const details = anomaly?.details?.details.map((detail) => detail.description) || [];
    getDataAnomalySummary(migrationId, {
      ruleId: rule.ruleId,
      record: node,
      details: details,
      recordId: node.id,
      tableName: node.label,
    })
      .then((res) => {
        console.log('AI Summary res', res);
        setSummary(res.summary);
      })
      .catch((err) => {
        console.log('AI Summary err', err);
      })
      .finally(() => setLoading(false));
  }, [anomaly?.details?.details, migrationId, node, rule.ruleId]);

  useEffect(() => {
    if (aiRecommendation?.payload?.fieldRecommendation) {
      setFieldRecommendation(aiRecommendation.payload.fieldRecommendation);
    }
  }, [aiRecommendation?.payload?.fieldRecommendation]);

  useEffect(() => {
    if (aiRecommendation?.payload?.summary?.length ?? 0 > 0) {
      setSummary(aiRecommendation?.payload?.summary);
      setLoading(false);
    } else {
      handleGetAnomalySummary();
    }
  }, [aiRecommendation?.payload?.summary, handleGetAnomalySummary]);

  const getFieldDetailList = useCallback(() => {
    if (loading === true) {
      return (
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      );
    }
    const detailMap: { [x: string]: DataAnomalyFieldDetailDescriptionMerged } = {};
    const detailsCopy: DataAnomalyFieldDetail[] = JSON.parse(JSON.stringify(anomaly?.details?.details || []));
    detailsCopy.forEach((detail) => {
      if (!detailMap[detail.field]) {
        detailMap[detail.field] = { ...detail, description: [detail.description] };
      } else {
        detailMap[detail.field].description.push(detail.description);
      }
    });

    return Object.keys(detailMap).map((fieldName, index) => {
      return (
        <DataAnomalyCardFieldDetail
          key={`data-anomaly-card-field-detail${index}`}
          fieldName={detailMap[fieldName].field}
          details={(summary?.length || 0) > 0 ? [summary || ''] : detailMap[fieldName].description}
          currentValue={detailMap[fieldName].value}
          recommendation={fieldRecommendation?.[fieldName]}
          migrationId={migrationId}
          rule={rule}
          node={node}
          recommendationFeedBack={aiRecommendation?.feedback}
          menuAnchorEl={anchorEl}
          menuOpen={menuOpen}
          handleMenuClose={handleCloseMenu}
        />
      );
    });
  }, [
    aiRecommendation?.feedback,
    anchorEl,
    anomaly?.details?.details,
    fieldRecommendation,
    loading,
    menuOpen,
    migrationId,
    node,
    rule,
    summary,
  ]);

  const FieldDetailList = useMemo(() => getFieldDetailList(), [getFieldDetailList]);

  return (
    <Grid container sx={{ display: 'flex', flexDirection: 'column', padding: '5px' }}>
      <Grid
        container
        sx={{
          display: 'flex',
          border: `5px solid ${theme.palette.lightBg.main}`,
          borderRadius: '4px',
          padding: '0.5rem',
          gap: '0.4rem',
        }}
      >
        {/* Header */}
        <Grid container sx={{ display: 'flex' }} justifyContent={'space-between'} alignItems={'start'}>
          <Grid item flex={5}>
            <Typography sx={{ fontSize: '12px', fontWeight: 'bold' }}>{rule.name}</Typography>
          </Grid>
          <Grid item sx={{ display: 'flex', justifyContent: 'space-between', gap: 1, minWidth: 'fit-content' }}>
            <Box
              sx={{
                ':hover': { cursor: 'pointer', fill: theme.palette.primary.main, stroke: theme.palette.primary.main },
              }}
            >
              <DotsIcon height={'100%'} onClick={handleOpenMenu} />
            </Box>
          </Grid>
        </Grid>
        {/* Divider */}
        <Divider sx={{ width: '100%' }} />
        <Grid container sx={{ display: 'flex', gap: 2 }}>
          {FieldDetailList}
        </Grid>
      </Grid>
    </Grid>
  );
};
