import { useEffect, useState } from 'react';

import { Box, MenuItem, Paper, Skeleton, Typography } from '@mui/material';

import { DataQualityTimeline } from '../DataQualityTimeline/DataQualityTimeline';
import { IntegrationActivityTable } from '../IntegrationActivity/IntegrationActivityTable';
import { ActiveRulesAndScoreImpact } from '../ActiveRulesAndImpactScores/ActiveRulesAndScoreImpact';

import DataHealthTabPanel from './DataHealthPanel';
import DataHealthMetrics from './DataHealthMetrics';

import { useGetMigrationsQuery, useLazyGetMigrationTablesByIdQuery } from 'store/migration/api';

import { Show } from 'components/show';
import { Migration, MigrationTable } from 'store/migration/types';
import { AutocompleteSelect } from 'components/AutocompleteSelect';
import { faker } from '@faker-js/faker';

const generateData = (numElements: number) => {
  const data = [];

  for (let i = 0; i < numElements; i++) {
    data.push({
      companyName: faker.company.name(),
      revenue: faker.finance.amount({ min: 1, max: 100, dec: 0, symbol: '$' }),
      accountOwner: faker.person.fullName(),
      lastContactDate: faker.date
        .past()
        .toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' }),
      leads: faker.number.int({ min: 0, max: 10 }),
      opportunities: faker.number.int({ min: 0, max: 10 }),
      contacts: faker.number.int({ min: 0, max: 10 }),
      confidenceScore: faker.number.int({ min: 0, max: 100 }),
    });
  }

  return data;
};

const useDefaultMigration = () => {
  const migrationsQuery = useGetMigrationsQuery();
  const [defaultTable, setDefaultTable] = useState<MigrationTable | null>(null);
  const [defaultMigration, setDefaultMigration] = useState<Migration | null>(null);
  const [getMigrationTablesById, migrationTablesQuery] = useLazyGetMigrationTablesByIdQuery();

  const handleMigrationData = (migrationId: string) => {
    const migration = migrationsQuery.data?.find((m) => {
      return m.dataMigrationId === migrationId;
    });

    if (migration && defaultMigration?.dataMigrationId !== migration.dataMigrationId) {
      setDefaultMigration(migration);
      getMigrationTablesById({ migrationId });
    }
  };

  useEffect(() => {
    if (migrationsQuery.data && migrationsQuery.data?.length > 0) {
      const [firstMigration] = migrationsQuery.data;
      handleMigrationData(firstMigration.dataMigrationId);
    }
  }, [migrationsQuery.isSuccess]);

  useEffect(() => {
    if (migrationTablesQuery.data && migrationTablesQuery.data?.length > 0) {
      console.table(migrationTablesQuery.data);
      const [firstTable] = migrationTablesQuery.data;
      setDefaultTable(firstTable);
    }
  }, [migrationTablesQuery.isSuccess]);

  return {
    migrationsQuery,
    handleMigrationData,
    defaultMigration: {
      ...defaultMigration,
      defaultTable,
      setDefaultTable,
      migrationTablesQuery,
    },
  };
};

export function DataHealthTab() {
  const { defaultMigration } = useDefaultMigration();

  return (
    <>
      <Paper elevation={0} sx={{ padding: 2 }}>
        <Box width={'100%'} typography={'body1'}>
          <Box px={1.5} display={'flex'} alignItems={'start'} flexDirection={'row'} justifyContent={'space-between'}>
            <div>
              <Typography variant="h2" fontSize={20}>
                Data Health Map{' '}
                <span
                  style={{
                    fontSize: 12,
                  }}
                >
                  {defaultMigration.dataSource?.name ?? 'loading'}
                </span>
              </Typography>
              <Show when={!!defaultMigration.defaultTable} fallback={<Skeleton height={50} />}>
                <AutocompleteSelect
                  options={defaultMigration.migrationTablesQuery.data ?? []}
                  defaultValue={defaultMigration.migrationTablesQuery.data?.find((t) => {
                    return t.table_name === defaultMigration.defaultTable?.table_name;
                  })}
                  getOptionLabel={(option) => option.table_name}
                  onChange={(_, value) => defaultMigration.setDefaultTable(value)}
                  isOptionEqualToValue={(o, v) => o.table_name === v.table_name}
                  renderOption={(props, option) => (
                    <MenuItem {...props} key={option.table_name} value={option.table_name}>
                      {option.table_name}
                    </MenuItem>
                  )}
                />
              </Show>
            </div>
            <DataHealthMetrics migrationTable={defaultMigration.defaultTable} />
          </Box>
          <Box paddingX={2}>
            <Show when={!!defaultMigration.defaultTable} fallback={<Skeleton height={500} width={'100%'} />}>
              <DataHealthTabPanel
                elements={generateData(defaultMigration.defaultTable?.row_count ?? 1000)}
                numRecords={defaultMigration.defaultTable?.row_count ?? 0}
              />
            </Show>
          </Box>
        </Box>
      </Paper>
      <br />
      <DataQualityTimeline />
      <br />
      <ActiveRulesAndScoreImpact />
      <br />
      <IntegrationActivityTable />
      <br />
    </>
  );
}
