import { useState, SyntheticEvent } from 'react';
import { Box, Button, ButtonGroup, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

import { TabItem } from 'pages/Integration/ui';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface DatasetItem {
  value: number;
  name: string;
}

interface ChartData {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    borderRadius: number;
    backgroundColor: string;
  }[];
}

const padName = (name: string, length: number) => {
  const paddedName = name.padEnd(length);
  return paddedName.length > length ? `${paddedName.substring(0, length - 3)}...` : paddedName;
};

const generateDescendingDataset = (numElements: number): DatasetItem[] => {
  const dataset: DatasetItem[] = [];
  const maxLength = 15;
  const values = Array.from({ length: numElements }, (_, i) => 500 - i * (500 / (numElements - 1)));

  for (let i = 0; i < numElements; i++) {
    const companyName = faker.company.name();
    const paddedCompanyName = `${i + 1}. ${padName(companyName, maxLength)}`;
    dataset.push({
      value: Math.round(values[i]),
      name: paddedCompanyName,
    });
  }

  return dataset;
};

const options = {
  indexAxis: 'y' as const,
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  scales: {
    x: {
      ticks: {
        stepSize: 100,
        grid: {
          offset: true,
        },
        font: {
          family: 'monospace',
        },
      },
      border: {
        dash: [5, 5],
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      grid: {
        display: false,
      },
    },
  },
};

const dataset = generateDescendingDataset(10);

const data: ChartData = {
  labels: dataset.map((item) => item.name),
  datasets: [
    {
      label: 'value',
      data: dataset.map((item) => item.value),
      borderRadius: 4,
      backgroundColor: '#1554FF',
    },
  ],
};

const periods = ['Week', 'Month', 'Quarter', 'Year', 'All time', 'Custom'];

const useTabHandler = () => {
  const [value, setValue] = useState('1');
  const handleChange = (e: SyntheticEvent, newValue: string) => setValue(newValue);
  return { value, handleChange };
};

export const TopAnomaliesDetected = () => {
  const { value, handleChange } = useTabHandler();

  return (
    <Box p={2}>
      <Typography fontSize={20} fontWeight={600}>
        Top 10 Anomalies Detected
      </Typography>
      <TabContext value={value}>
        <TabList onChange={handleChange}>
          <TabItem label="Accounts" value="1" />
          <TabItem label="Contacts" value="2" />
          <TabItem label="Leads" value="3" />
          <TabItem label="Opportunities" value="4" />
        </TabList>
        <TabPanel value="1" sx={{ px: 0 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography fontSize="12px" color="#9DA1B5">
              Feb 1, 2022 to Feb 28, 2022
            </Typography>
            <ButtonGroup
              sx={{
                '& .MuiButtonGroup-grouped': {
                  height: '16px',
                  bgcolor: 'white',
                  color: '#898EA1',
                  border: 'none',
                  fontSize: '12px',
                  fontWeight: 400,
                  '&:hover': { bgcolor: 'white', color: '#898EA1', fontWeight: 600, border: 'none' },
                },
              }}
            >
              {periods.map((period, index) => (
                <Button key={index}>{period}</Button>
              ))}
            </ButtonGroup>
          </Box>
          <Bar style={{ paddingTop: 4 }} options={options} data={data} />
        </TabPanel>
        <TabPanel value="2">Contacts</TabPanel>
        <TabPanel value="3">Leads</TabPanel>
        <TabPanel value="4">Opportunities</TabPanel>
      </TabContext>
    </Box>
  );
};
