import { Box, Typography } from '@mui/material';
import { ReactComponent as RecommendationIcon } from 'assets/icons/recommendationStart.svg';
import theme from 'core/theme';
import { FC, useCallback } from 'react';

interface SmartRecommendationFieldProps {
  handleGetAnomalyFieldRecommendation: () => void;
  haveRecommendation: boolean;
  recommendationCalculated?: string;
}

export const SmartRecommendationField: FC<SmartRecommendationFieldProps> = (props) => {
  const { handleGetAnomalyFieldRecommendation, haveRecommendation, recommendationCalculated } = props;

  const handleCallGetAnomalyFieldRecommendation = useCallback(() => {
    if (!recommendationCalculated) {
      handleGetAnomalyFieldRecommendation();
    }
  }, [handleGetAnomalyFieldRecommendation, recommendationCalculated]);

  return (
    <Box
      sx={{
        color: theme.palette.primary.main,
        display: 'flex',
        ':hover': haveRecommendation
          ? {}
          : {
              cursor: 'pointer',
              color: theme.palette.neutral.white,
              backgroundColor: theme.palette.primary.main,
              borderRadius: '5px',
            },
      }}
      onClick={handleCallGetAnomalyFieldRecommendation}
    >
      <Box
        flex={10}
        sx={{
          border: `1px dotted ${theme.palette.primary.main}`,
          borderRight: `1px solid ${theme.palette.primary.main}`,
          borderRadius: '5px 0px 0px 5px',
          height: '32px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {haveRecommendation ? (
          <Typography variant="p16" sx={{ color: theme.palette.neutral.main }}>
            {recommendationCalculated}
          </Typography>
        ) : (
          <Typography variant="p16">Find the best recommendation</Typography>
        )}
      </Box>
      <Box
        flex={1}
        sx={{
          backgroundColor: theme.palette.primary.main,
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: '0px 5px 5px 0px',
          height: '32px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <RecommendationIcon />
      </Box>
    </Box>
  );
};
