import React from 'react';

import { LinearProgress, Box, Typography } from '@mui/material';

type LinearProgressCustomProps = {
  label?: React.ReactNode;
  value: number;
  borderRadius?: number | string;
  justifyContent?: string;
  fontSize?: number | string;
  height?: number | string;
  width?: number | string;
};

function getColor(minValue: number, mediunValue: number, maxValue: number, value: number) {
  if (minValue <= value && mediunValue > value) {
    return '#FB4E6D';
  } else if (mediunValue <= value && maxValue > value) {
    return '#FF9960';
  } else if (maxValue <= value) {
    return '#60C67C';
  }

  return 'white';
}

export default function LinearProgressCustom({
  label,
  value,
  justifyContent = 'space-between',
  borderRadius = '8px',
  fontSize = '10px',
  width = '80px',
  height = '20px',
}: LinearProgressCustomProps) {
  return (
    <Box display="flex" color="#9da1b5" justifyContent={justifyContent} pt={1} alignItems="center">
      {label ?? <Typography fontSize={12}>{label}</Typography>}
      <Box position="relative" display="inline-flex" width={width} height={height}>
        <LinearProgress
          value={value}
          variant="determinate"
          sx={{
            borderRadius: borderRadius,
            width: '100%',
            height: height,
            backgroundColor: '#DCE5F3',
            '& .MuiLinearProgress-bar': {
              backgroundColor: getColor(0, 33, 66, value),
            },
          }}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography sx={{ fontSize: fontSize, color: 'black' }}>{`Average ${value}%`}</Typography>
        </Box>
      </Box>
    </Box>
  );
}
