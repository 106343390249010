import { useRef, useState, useEffect, createRef } from 'react';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { getIntegrationActivityDataColumns, IntegrationActivityData } from './integrationActivityUtils';
import { ColumnSortedAscendingIcon, ColumnSortedDescendingIcon, ColumnUnsortedIcon } from 'pages/DataRaptor/ui';
import { Box } from '@mui/material';

interface IntegrationActivityDataGridProps {
  data: IntegrationActivityData[];
}

const IntegrationActivityDataGrid = ({ data }: IntegrationActivityDataGridProps) => {
  const ref = createRef<HTMLDivElement>();
  const [columns, setColumns] = useState(getIntegrationActivityDataColumns(0));

  const handleResize = () => {
    const width = ref.current?.clientWidth ?? 100;
    setColumns(getIntegrationActivityDataColumns(width));
  };

  useEffect(() => {
    handleResize();
    ref.current?.addEventListener('resize', handleResize);
    return () => {
      ref.current?.removeEventListener('resize', handleResize);
    };
  }, [ref.current]);

  return (
    <Box py={1} ref={ref} width={'100%'} height={'95%'}>
      <DataGrid
        hideFooter
        pageSize={3}
        rows={data}
        columns={columns}
        headerHeight={30}
        density="comfortable"
        getRowId={(r) => r.id}
        disableSelectionOnClick
        hideFooterSelectedRowCount
        components={{
          ColumnUnsortedIcon,
          ColumnSortedAscendingIcon,
          ColumnSortedDescendingIcon,
        }}
      />
    </Box>
  );
};

export default IntegrationActivityDataGrid;
