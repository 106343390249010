import { useCallback, useMemo, useState } from 'react';

import { Box, Button, Grid, LinearProgress, Paper, Typography } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { ResponsiveBullet } from '@nivo/bullet';

import { ModalTemplate } from 'components/ModalTemplate';

import { TabItem } from 'pages/Integration/ui';

import { useRuleFormModalHook } from 'pages/RuleLibrary/components/RuleFormModal/RuleFormModal.hook';
import { TemporalTableModal } from 'pages/RuleLibrary/components/TemporalTableModal/TemporalTableModal';
import { RuleFormModal } from 'pages/RuleLibrary/components/RuleFormModal/RuleFormModal';

import { useRuleLibrary } from 'store/ruleLibrary/hook';

import ActiveRulesCategory from './ActiveRuleCategory';
import AISuggestedCategory from './AISuggestedCategory';
import { TopAnomaliesDetected } from './TopAnomaliesDetected';

const data = [
  {
    id: 'confidence',
    ranges: [],
    measures: [40, 65, 100],
    markers: [40],
  },
];

const useLoadingState = () => {
  const {
    action: {
      postEmbedDDLSchema: { loading: postEmbedDDLSchemaLoading },
      getAIPoweredRuleTemplate: { loading: getAIPoweredRuleTemplateLoading },
    },
  } = useRuleLibrary();

  return useMemo(
    () => postEmbedDDLSchemaLoading || getAIPoweredRuleTemplateLoading,
    [getAIPoweredRuleTemplateLoading, postEmbedDDLSchemaLoading],
  );
};

const useTabs = () => {
  const [value, setValue] = useState('1');
  const handleChange = useCallback((e: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  }, []);
  return { value, handleChange };
};

type LoadingModelProps = {
  loading: boolean;
};

const LoadingModal = ({ loading }: LoadingModelProps) => (
  <ModalTemplate
    title="Loading..."
    showChevronDown={false}
    showCloseButton={false}
    open={loading}
    toggleOpen={() => undefined}
  >
    <Grid container display="flex" flexDirection="column" spacing={3} py={5} px={5}>
      <Grid item sx={{ textAlign: 'center' }}>
        <Typography variant="h3">
          <strong>Generating your rule based on your data schema</strong>
        </Typography>
      </Grid>
      <Grid item>
        <LinearProgress />
      </Grid>
    </Grid>
  </ModalTemplate>
);

export const ActiveRulesAndScoreImpact = () => {
  const templateActionLoading = useLoadingState();
  const { value, handleChange } = useTabs();
  const { openRuleFormModalForCreation } = useRuleFormModalHook();

  return (
    <Box display="flex" justifyContent="space-between" width="100%" gap={2}>
      <Paper elevation={0} sx={{ width: '100%' }}>
        <Box p={2}>
          <Box display="flex" alignItems="center" textTransform="capitalize" justifyContent="space-between">
            <Typography fontSize={20} fontWeight={600}>
              Active Rules and Impact on Scores
            </Typography>
            <Button
              variant="text"
              onClick={openRuleFormModalForCreation}
              sx={{
                ':hover': {
                  backgroundColor: 'transparent',
                  color: 'inherit',
                },
                ':focus': {
                  backgroundColor: 'transparent',
                  color: 'inherit',
                },
              }}
            >
              + Create New Rule
            </Button>
          </Box>
          <TabContext value={value}>
            <TabList onChange={handleChange}>
              <TabItem label="Accounts" value="1" />
              <TabItem label="Contacts" value="2" />
              <TabItem label="Leads" value="3" />
              <TabItem label="Opportunities" value="4" />
            </TabList>
            <TabPanel value="1" sx={{ px: 0 }}>
              <Box>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography fontSize={12} fontWeight={500}>
                    OVERALL CONFIDENCE SCORE
                  </Typography>
                  <Box display="flex" justifyContent="space-between" alignItems="end" gap={2}>
                    <Typography fontSize={12} color="#898EA1">
                      (40% WITHOUT RULES)
                    </Typography>
                    <Typography fontSize={16} fontWeight={600}>
                      65%
                    </Typography>
                  </Box>
                </Box>
                <Box height={70}>
                  <ResponsiveBullet
                    data={data}
                    margin={{ left: 2, right: 10, bottom: 50 }}
                    maxValue={100}
                    rangeColors={['white']}
                    measureColors={['#FF9960', '#D67E4C', '#F8E6DF']}
                    markerColors={['black']}
                    titleOffsetX={-40}
                    tooltip={() => <></>}
                  />
                </Box>
                <Box>
                  <ActiveRulesCategory />
                  <AISuggestedCategory />
                </Box>
              </Box>
            </TabPanel>
            <TabPanel value="2">Contacts</TabPanel>
            <TabPanel value="3">Leads</TabPanel>
            <TabPanel value="4">Opportunities</TabPanel>
          </TabContext>
          <>
            <LoadingModal loading={templateActionLoading} />
            <TemporalTableModal />
            <RuleFormModal />
          </>
        </Box>
      </Paper>
      <Paper elevation={0} sx={{ width: '100%' }}>
        <TopAnomaliesDetected />
      </Paper>
    </Box>
  );
};
