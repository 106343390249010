import backendApi from 'store/api/backendApi';

import { Condition } from 'http/migration/dto';
import { Migration, MigrationTable } from './types';

export type WithIcon<T> = {
  icon?: JSX.Element;
} & T;

interface getMigrationTableDataByIdParams {
  migrationId: string;
  tableId: string;
  skip: number;
  take: number;
  conditions?: Condition[];
  fields?: string[];
  action?: string;
}

export const migrationApi = backendApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getMigrations: builder.query<Migration[], void>({
        query: () => ({
          method: 'get',
          url: '/dataMigration',
        }),
      }),
      getMigrationTablesById: builder.query<MigrationTable[], { migrationId: string }>({
        query: ({ migrationId }) => ({
          method: 'get',
          url: `dataMigration/${migrationId}/tables`,
        }),
        transformResponse(baseQueryReturnValue: MigrationTable[]) {
          return baseQueryReturnValue.map((table) => ({
            ...table,
            avg_confidence_score: Math.ceil(100 * Math.random()),
            row_count: Math.ceil(2000 * Math.random()),
          }));
        },
      }),
      getMigrationTableDataById: builder.query<any[], getMigrationTableDataByIdParams>({
        query: ({ migrationId, tableId, skip, take, conditions = [], fields = [], action = 'retrieve' }) => ({
          method: 'post',
          url: `/dataMigration/${migrationId}/table/${tableId}/data?skip=${skip}&take=${take}&action=${action}`,
          body: { conditions, fields },
        }),
      }),
    };
  },
});

export const {
  usePrefetch,
  useGetMigrationsQuery,
  useLazyGetMigrationsQuery,
  useGetMigrationTablesByIdQuery,
  useGetMigrationTableDataByIdQuery,
  useLazyGetMigrationTablesByIdQuery,
  useLazyGetMigrationTableDataByIdQuery,
} = migrationApi;
