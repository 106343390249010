import { useState } from 'react';

import { Box, Button, ButtonGroup, Grid, Paper, Typography } from '@mui/material';

import { TimeLinearChart } from './TimeLinearChart';
import { DataQualityTable } from './DataQualityTable';

type AverageConfidenceScore =
  | {
      createdAt: Date;
      vsPreviousMonth: number;
      avgConfidenceScore: number;
    }
  | undefined;

const defaultAvgConfidenceScore: AverageConfidenceScore = {
  createdAt: new Date(),
  vsPreviousMonth: -2.5,
  avgConfidenceScore: 60,
};

const periods = ['Week', 'Month', 'Quarter', 'Year', 'All time', 'Custom'];

type Legends = {
  name: string;
  color: string;
};

const legends: Legends[] = [
  { color: '#0052D2', name: 'Accounts' },
  { color: '#8CB9FF', name: 'Contacts' },
  { color: '#00CEDB', name: 'Opportunities' },
];

const PeriodSelector = ({ periods }: { periods: string[] }) => (
  <ButtonGroup
    sx={{
      '& .MuiButtonGroup-grouped': {
        height: '24px',
        bgcolor: 'white',
        color: '#898EA1',
        border: 'none',
        fontWeight: 400,
        '&:hover': { bgcolor: 'white', color: '#898EA1', fontWeight: 600, border: 'none' },
      },
    }}
  >
    {periods.map((period, index) => (
      <Button key={index}>{period}</Button>
    ))}
  </ButtonGroup>
);

const Legend = ({ legends }: { legends: Legends[] }) => (
  <Box display="flex" alignItems="flex-start" paddingTop={4}>
    {legends.map((legend, index) => (
      <Box key={index} marginRight={3} display="flex" alignItems="center" gap={1.5}>
        <div
          style={{
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            backgroundColor: legend.color,
          }}
        />
        <Typography fontSize={'12px'} fontWeight={400}>
          {legend.name}
        </Typography>
      </Box>
    ))}
  </Box>
);

export const DataQualityTimeline = () => {
  const [avgConfidenceScore, setAvgConfidenceScore] = useState<AverageConfidenceScore>();

  return (
    <Paper elevation={0} sx={{ padding: 2 }}>
      <Grid container p={12} px={1} py={1}>
        <Grid item xs={avgConfidenceScore ? 6 : 12}>
          <Box display="flex" alignItems="center" textTransform="capitalize" justifyContent="space-between">
            <Typography variant="h2" fontSize={18}>
              Data Quality Timeline <span style={{ fontSize: 14 }}>object view</span>
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" paddingY={2}>
            <Box display="flex" alignItems="center" gap={1}>
              <Typography fontSize="12px" color="#9DA1B5">
                Start date:
              </Typography>
              <Typography fontSize="12px" fontWeight={600}>
                Mar 1, 2022
              </Typography>
            </Box>
            <PeriodSelector periods={periods} />
          </Box>
          <Box height={300} onClick={() => setAvgConfidenceScore(defaultAvgConfidenceScore)}>
            <TimeLinearChart legends={legends} />
          </Box>
          <Legend legends={legends} />
        </Grid>
        {avgConfidenceScore && (
          <Grid item xs={6}>
            <DataQualityTable closeView={() => setAvgConfidenceScore(undefined)} />
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};
