import { Box, CircularProgress, Divider, Menu, MenuItem, MenuList, Typography } from '@mui/material';
import { ReactComponent as EditIcon } from 'assets/icons/edit-no-bg.svg';
import { ReactComponent as ReloadIcon } from 'assets/icons/reload.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close-square.svg';
import theme from 'core/theme';
import { FC } from 'react';

export interface DataAnomalyCardMenuProps {
  anchorEl: null | HTMLElement;
  open: boolean;
  handleClose: () => void;
  handleAcceptRecommendation: () => void;
  handleCreateTask: () => void;
  actionLoading?: boolean;
}

export const DataAnomalyCardMenu: FC<DataAnomalyCardMenuProps> = (props) => {
  const { anchorEl, open, actionLoading, handleClose, handleAcceptRecommendation, handleCreateTask } = props;
  const boxStyle = { display: 'flex', alignItems: 'center', gap: 0.5 };
  const iconStyle = { stroke: theme.palette.neutral.main };
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      {actionLoading === true ? (
        <MenuItem sx={{ justifyContent: 'center' }}>
          <Box sx={boxStyle}>
            <CircularProgress size={'1rem'} />
            <Typography>Applying action</Typography>
          </Box>
        </MenuItem>
      ) : (
        <MenuList>
          <MenuItem onClick={handleAcceptRecommendation}>
            <Box sx={boxStyle}>
              <ReloadIcon style={iconStyle} />
              <Typography>Accept Recommendation</Typography>
            </Box>
          </MenuItem>
          <MenuItem onClick={handleCreateTask}>
            <Box sx={boxStyle}>
              <EditIcon style={iconStyle} />
              <Typography>Add Task</Typography>
            </Box>
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>
            <Box sx={boxStyle}>
              <CloseIcon style={iconStyle} />
              <Typography>Ignore</Typography>
            </Box>
          </MenuItem>
        </MenuList>
      )}
    </Menu>
  );
};
