import React from 'react';
import { Grid, Button, Tooltip, Typography } from '@mui/material';

type ScoreFilterButtonsProps = {
  selectedButton: number;
  setSelectedButton: (index: number) => void;
};

const ScoreFilterButtons: React.FC<ScoreFilterButtonsProps> = ({ selectedButton, setSelectedButton }) => (
  <Grid container>
    <Tooltip title="Select a score range to filter the heatmap">
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          {['#FB4E6D', '#FF9960', '#60C67C'].map((color, index) => (
            <Button
              key={color}
              variant="contained"
              onClick={() => setSelectedButton(index + 1)}
              sx={{
                bgcolor: selectedButton === index + 1 || selectedButton === 0 ? color : '#898EA1',
                width: '33%',
                height: '100%',
                p: '4px',
                borderRadius: '2px',
                '&:hover': {
                  boxShadow: 'none',
                  bgcolor: color,
                },
              }}
            />
          ))}
        </Grid>
      </Grid>
    </Tooltip>
    <Grid item xs={12}>
      <Grid container justifyContent="space-between">
        <Typography fontSize={12} sx={{ color: '#898EA1' }}>
          Poor (123)
        </Typography>
        <Typography fontSize={12} sx={{ color: '#898EA1' }}>
          Average (98)
        </Typography>
        <Typography fontSize={12} sx={{ color: '#898EA1' }}>
          Good (292)
        </Typography>
      </Grid>
    </Grid>
  </Grid>
);

export default ScoreFilterButtons;
