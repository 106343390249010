import { FormEventHandler, useCallback, useMemo, useState } from 'react';
import { Box, MenuItem, TextField, ListItemIcon, Divider, Tooltip, InputAdornment } from '@mui/material';
import { getAnomalyEmptyRootConditional, getDataValidationEmptyRootConditional } from 'pages/RuleForm/utils';
import { useRuleFormStore } from 'store/ruleForm/hook';
import { RuleFormDetailsRelatedTables } from './RelatedTables/RuleFormDetailsRelatedTables';
import { ReactComponent as AISuggestionIcon } from 'assets/icons/AIChat/aiSuggestion.svg';
import ToolTipTexts from './TooltipTexts';
import {
  useGetRuleRisksQuery,
  useGetRuleTypesQuery,
  useGetRuleDepartmentsQuery,
  WithIcon,
  useGetRulesByMigrationAndTableNameQuery,
} from 'store/ruleForm/api';
import { ControlledCustomDropdown } from 'components/CustomDropdown';
import { useMigration } from 'store/migration/hooks';
import { useRuleFormModalHook } from '../../../RuleFormModal.hook';
import { useDataRaptorRule } from 'store/dataRaptorRule/hooks';
import { RuleFormMode } from 'pages/RuleLibrary/types';
import { LabelComponent } from '../../../../ui/LabelComponent';
import { FieldError } from 'react-hook-form';

interface Option {
  label: string;
  value: string;
}

type RuleFormRuleDetailsProps = {
  handleNext: () => void;
};

export const RuleFormRuleDetails = ({ handleNext }: RuleFormRuleDetailsProps) => {
  const ruleForm = useRuleFormStore();
  const [errors, setErrors] = useState<{ name?: FieldError; description?: FieldError; violationScore?: FieldError }>(
    {},
  );
  const {
    aiAssistant: { suggestions },
  } = ruleForm;
  const {
    data: { selectedRuleId, formMode },
  } = useDataRaptorRule();
  const {
    data: { migrationId },
  } = useMigration();
  const {
    data: { selectedTable: tableName },
  } = useRuleFormModalHook();

  const usingSuggestedRuleName = useMemo(
    () => ruleForm.ruleName === suggestions.ruleName,
    [ruleForm.ruleName, suggestions.ruleName],
  );
  const usingSuggestedDescription = useMemo(
    () => ruleForm.description === suggestions.description,
    [ruleForm.description, suggestions.description],
  );
  const usingSuggestedDepartment = useMemo(
    () => ruleForm.department === suggestions.department,
    [ruleForm.department, suggestions.department],
  );
  const usingSuggestedRiskLevel = useMemo(
    () => ruleForm.riskLevel === suggestions.riskLevel,
    [ruleForm.riskLevel, suggestions.riskLevel],
  );
  const usingSuggestedRuleType = useMemo(
    () => ruleForm.category === suggestions.ruleType,
    [ruleForm.category, suggestions.ruleType],
  );
  const usingSuggestedViolationScore = useMemo(
    () => ruleForm.violationScore === suggestions.violationScore,
    [ruleForm.violationScore, suggestions.violationScore],
  );

  const ruleRisksQuery = useGetRuleRisksQuery();
  const ruleTypesQuery = useGetRuleTypesQuery();
  const ruleDepartmentsQuery = useGetRuleDepartmentsQuery();

  const rulesQuery = useGetRulesByMigrationAndTableNameQuery({
    tableName,
    migrationId,
  });

  const onSubmit = useCallback(
    (e: any) => {
      e.preventDefault();
      const errors: any = {};
      if (!ruleForm.ruleName) {
        errors['name'] = { type: 'manual', message: 'Rule name is required' };
      }
      if (!ruleForm.description) {
        errors['description'] = { type: 'manual', message: 'Rule description is required' };
      }
      if (ruleForm.violationScore < 0 || ruleForm.violationScore > 100) {
        errors['violationScore'] = { type: 'manual', message: 'Violation score must greater than 0' };
      }

      const existingRule = rulesQuery.data?.find((r) => {
        return (
          (r.name === ruleForm.ruleName && formMode == RuleFormMode.CREATE) ||
          (r.name === ruleForm.ruleName && r.ruleId !== selectedRuleId && formMode != RuleFormMode.CREATE)
        );
      });
      if (existingRule) {
        errors['name'] = { type: 'manual', message: 'Rule name already exists for this table' };
      }
      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        return;
      } else {
        setErrors({});
        handleNext();
      }
    },
    [
      formMode,
      handleNext,
      ruleForm.description,
      ruleForm.ruleName,
      ruleForm.violationScore,
      rulesQuery.data,
      selectedRuleId,
    ],
  );

  const ruleTypeIcon = useMemo(() => {
    if (usingSuggestedRuleType) {
      return (
        <InputAdornment position="start">
          <AISuggestionIcon />
        </InputAdornment>
      );
    }
    return ruleTypesQuery.data?.find((r) => r.name === ruleForm.category)?.icon;
  }, [ruleForm.category, ruleTypesQuery.data, usingSuggestedRuleType]);

  const ruleRiskIcon = useMemo(() => {
    if (usingSuggestedRiskLevel) {
      return (
        <InputAdornment position="start">
          <AISuggestionIcon />
        </InputAdornment>
      );
    }
    return ruleRisksQuery.data?.find((r) => r.name === ruleForm.riskLevel)?.icon;
  }, [ruleForm.riskLevel, ruleRisksQuery.data, usingSuggestedRiskLevel]);

  const ruleNameInputAdornment = useMemo(() => {
    return usingSuggestedRuleName
      ? {
          startAdornment: (
            <InputAdornment position="start">
              <AISuggestionIcon />
            </InputAdornment>
          ),
        }
      : {};
  }, [usingSuggestedRuleName]);

  const ruleDepartmentInputAdornment = useMemo(() => {
    return usingSuggestedDepartment
      ? {
          startAdornment: (
            <InputAdornment position="start">
              <AISuggestionIcon />
            </InputAdornment>
          ),
        }
      : {};
  }, [usingSuggestedDepartment]);

  const ruleDescriptionInputAdornment = useMemo(() => {
    return usingSuggestedDescription
      ? {
          startAdornment: (
            <InputAdornment position="start">
              <AISuggestionIcon />
            </InputAdornment>
          ),
        }
      : {};
  }, [usingSuggestedDescription]);

  const handleCategoryChange = useCallback(
    (value: string) => {
      ruleForm.setCategory(value);
      if (value === 'data-validation') {
        ruleForm.setWhere({ value: [getDataValidationEmptyRootConditional()] });
      } else {
        ruleForm.setWhere({ value: [getAnomalyEmptyRootConditional()] });
      }
    },
    [ruleForm],
  );

  const ruleTypeDisabled = useMemo(() => {
    return ruleTypesQuery.isLoading || ruleTypesQuery.isError || formMode !== RuleFormMode.CREATE;
  }, [formMode, ruleTypesQuery.isError, ruleTypesQuery.isLoading]);

  return (
    <Box gap={2} display={'flex'} component={'form'} flexDirection={'column'} onSubmit={onSubmit}>
      <Box display={'flex'} flexDirection={'column'}>
        <LabelComponent label={'Rule Name'} toolTipText={ToolTipTexts.ruleName} />
        <TextField
          hiddenLabel
          error={!!errors.name}
          helperText={errors.name?.message}
          value={ruleForm.ruleName}
          onChange={(event) => ruleForm.setRuleName(event.target.value)}
          InputProps={ruleNameInputAdornment}
        />
      </Box>
      <Box display={'flex'} flexDirection={'column'}>
        <LabelComponent label={' Rule Description (optional)'} toolTipText={ToolTipTexts.description} />
        <TextField
          multiline
          hiddenLabel
          maxRows={3}
          error={!!errors.description}
          helperText={errors.description?.message}
          value={ruleForm.description}
          onChange={(event) => ruleForm.setDescription(event.target.value)}
          InputProps={ruleDescriptionInputAdornment}
        />
      </Box>
      <Box display={'flex'} flexDirection={'row'} gap={2} alignItems={'center'}>
        <Box flex={1}>
          <ControlledCustomDropdown<string>
            value={ruleForm.category}
            id={'rule-form-modal-rule-detail-category'}
            onSelect={handleCategoryChange}
            labelComponent={<LabelComponent label={'Rule Type'} toolTipText={ToolTipTexts.ruleType} />}
            placeholder="Select type"
            options={(ruleTypesQuery.data ?? []).map((r) => ({ label: r.label, value: r.name, icon: r.icon }))}
            disabled={ruleTypeDisabled}
            renderOption={(props, option: WithIcon<Option>) => (
              <Tooltip title={ToolTipTexts.ruleTypeMap[option.value]} placement={'right'}>
                <MenuItem {...props} key={option.label} value={option.value} sx={{ textTransform: 'capitalize' }}>
                  <ListItemIcon>{option.icon}</ListItemIcon>
                  {option.label}
                </MenuItem>
              </Tooltip>
            )}
            InputProps={{ startAdornment: ruleTypeIcon }}
            fallbackOption={{ label: 'Select type', value: '' }}
          />
        </Box>
        <Box flex={1}>
          <ControlledCustomDropdown<string>
            value={ruleForm.riskLevel}
            id={'rule-form-modal-rule-detail-risk-level'}
            onSelect={(value: string) => {
              ruleForm.setRiskLevel(value);
            }}
            labelComponent={<LabelComponent label={'Rule Level'} toolTipText={ToolTipTexts.riskLevel} />}
            placeholder="Select level"
            options={(ruleRisksQuery.data ?? []).map((r) => ({ label: r.label, value: r.name, icon: r.icon }))}
            disabled={ruleRisksQuery.isLoading}
            renderOption={(props, option: WithIcon<Option>) => (
              <MenuItem {...props} key={option.label} value={option.value} sx={{ textTransform: 'capitalize' }}>
                <ListItemIcon>{option.icon}</ListItemIcon>
                {option.label}
              </MenuItem>
            )}
            InputProps={{ startAdornment: ruleRiskIcon }}
            fallbackOption={{ label: 'Select Level', value: '' }}
          />
        </Box>
        <Box flex={1}>
          <ControlledCustomDropdown<string>
            value={ruleForm.department}
            id={'rule-form-modal-rule-detail-department'}
            onSelect={(value: string) => {
              ruleForm.setDepartment(value);
            }}
            labelComponent={<LabelComponent label={'Department'} toolTipText={ToolTipTexts.department} />}
            placeholder="Select Department"
            options={(ruleDepartmentsQuery.data ?? []).map((r) => ({ label: r.label, value: r.name }))}
            disabled={ruleDepartmentsQuery.isLoading}
            renderOption={(props, option) => (
              <MenuItem {...props} key={option.label} value={option.value} sx={{ textTransform: 'capitalize' }}>
                {option.label}
              </MenuItem>
            )}
            fallbackOption={{ label: 'Select Department', value: '' }}
            InputProps={ruleDepartmentInputAdornment}
          />
        </Box>
      </Box>
      <Divider sx={{ mt: 1, mb: 1, color: 'whitesmoke' }} />
      <RuleFormDetailsRelatedTables
        usingSuggestedAIValue={usingSuggestedViolationScore}
        setValue={(name: string, value: number) => {
          ruleForm.setViolationScore(value);
        }}
        error={errors.violationScore}
        value={ruleForm.violationScore}
      />
      <button hidden type="submit" id="ruleDetailForm">
        submit
      </button>
    </Box>
  );
};
