import { Divider, Grid, Typography } from '@mui/material';
import theme from 'core/theme';
import { FC, useCallback, useMemo, useState } from 'react';
import { getDataAnomalyFieldRecommendation, acceptAIRecommendation, RecommendationActionEnum } from 'http/ai';
import { DataRaptorRule } from 'store/dataRaptorRule/types';
import { SmartRecommendationField } from './SmartRecommendationField';
import { SmartRecommendationLoading } from './SmartRecommendationLoading';
import { useSnackbar } from 'notistack';
import { RecommendationFeedBack } from './RecommendationFeedBack';
import { AI_RECOMMENDATION_TYPE } from 'store/dataAnomaly/types';
import { DataAnomalyCardMenu } from './DataAnomalyCardMenu';
import { useGraphRender } from 'store/graphRender/hooks';
import { useCreateTaskFormStore } from 'store/createTaskForm/hooks';

export interface DataAnomalyCardFieldDetailProps {
  fieldName: string;
  details: string[];
  currentValue: string;
  recommendation?: string;
  migrationId: string;
  rule: DataRaptorRule;
  node: any;
  recommendationFeedBack?: boolean;
  menuAnchorEl: null | HTMLElement;
  menuOpen: boolean;
  handleMenuClose: () => void;
}

export const DataAnomalyCardFieldDetail: FC<DataAnomalyCardFieldDetailProps> = (props) => {
  const {
    currentValue,
    details,
    fieldName,
    recommendation,
    migrationId,
    rule,
    node,
    recommendationFeedBack,
    menuAnchorEl,
    handleMenuClose,
    menuOpen,
  } = props;
  const [loadedRecommendation, setLoadedRecommendation] = useState<string>();
  const [loadingRecommendation, setLoadingRecommendation] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const [menuActionLoading, setMenuActionLoading] = useState<boolean>(false);
  const { setShowCreateActivityModal } = useGraphRender();
  const { setAIDetail, setRequestDetails, setRequestRecord, setRequestTableName, setRequestRecordId } =
    useCreateTaskFormStore();

  const haveRecommendation = useMemo(() => {
    return (recommendation !== undefined || loadedRecommendation !== undefined) && loadingRecommendation !== true;
  }, [loadedRecommendation, loadingRecommendation, recommendation]);

  const recommendationCalculated = useMemo(() => {
    return loadedRecommendation || recommendation;
  }, [loadedRecommendation, recommendation]);

  const handleOpenCreateActivityMenu = useCallback(() => {
    const requestDetailsArray = details;
    if (recommendationCalculated) {
      requestDetailsArray.push(`Smart Recommendation: ${recommendationCalculated}`);
    }
    setAIDetail(details[0]);
    setShowCreateActivityModal(true);
    setRequestRecord(node);
    setRequestTableName(node.label);
    setRequestRecordId(node.id);
    setRequestDetails(requestDetailsArray);
    handleMenuClose();
  }, [
    details,
    handleMenuClose,
    node,
    recommendationCalculated,
    setAIDetail,
    setRequestDetails,
    setRequestRecord,
    setRequestRecordId,
    setRequestTableName,
    setShowCreateActivityModal,
  ]);

  const aiRecommendationKey = useMemo(() => {
    return `${node.label}#${node.id}#${rule.ruleId}#${AI_RECOMMENDATION_TYPE.DATA_ANOMALY}`;
  }, [node.id, node.label, rule.ruleId]);

  const handleAcceptRecommendation = useCallback(() => {
    setMenuActionLoading(true);
    acceptAIRecommendation(migrationId, aiRecommendationKey, fieldName, RecommendationActionEnum.ACCEPT)
      .then(() => {
        enqueueSnackbar('Recommendation accepted', { variant: 'success' });
        handleMenuClose();
      })
      .catch((err) => {
        enqueueSnackbar('Failed to accept recommendation', { variant: 'error' });
        console.log('AI Field Recommendation err', err);
      })
      .finally(() => setMenuActionLoading(false));
  }, [aiRecommendationKey, enqueueSnackbar, fieldName, handleMenuClose, migrationId]);

  const handleGetAnomalyFieldRecommendation = useCallback(() => {
    setLoadingRecommendation(true);
    getDataAnomalyFieldRecommendation(migrationId, {
      ruleId: rule.ruleId,
      record: node,
      details: [fieldName],
      recordId: node.id,
      tableName: node.label,
      fieldName: fieldName,
    })
      .then((res) => {
        console.log('AI Field Recommendation res', res);
        setLoadedRecommendation(res.recommendation);
      })
      .catch((err) => {
        enqueueSnackbar('Failed to get recommendation', { variant: 'error' });
        console.log('AI Field Recommendation err', err);
      })
      .finally(() => setLoadingRecommendation(false));
  }, [enqueueSnackbar, fieldName, migrationId, node, rule.ruleId]);

  const DetailComponent = useMemo(() => {
    if (details.length === 1) {
      return <Typography sx={{ lineHeight: '16px', fontSize: '14px', whiteSpace: 'pre-wrap' }}>{details}</Typography>;
    } else {
      return (
        <ul style={{ margin: 0, paddingInlineStart: '20px' }}>
          {details.map((detail, index) => (
            <li key={`${index}`}>
              <Typography sx={{ lineHeight: '16px', fontSize: '14px', whiteSpace: 'pre-wrap' }}>{detail}</Typography>
            </li>
          ))}
        </ul>
      );
    }
  }, [details]);
  return (
    <>
      <Grid
        container
        sx={{ border: `0.3rem solid ${theme.palette.lightBg.main}`, borderRadius: '4px', padding: '0.3rem', gap: 1 }}
      >
        <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
          <Grid item>
            <Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>{fieldName}</Typography>
          </Grid>
        </Grid>
        {/* Detail */}
        <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
          <Grid item>
            <Typography sx={{ color: theme.palette.neutral.n400, lineHeight: '16px', fontSize: '12px' }}>
              Details
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ lineHeight: '16px', fontSize: '14px', whiteSpace: 'pre-wrap' }}>
              {DetailComponent}
            </Typography>
          </Grid>
        </Grid>
        {/* Value */}
        <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
          <Grid item>
            <Typography sx={{ color: theme.palette.neutral.n400, lineHeight: '16px', fontSize: '12px' }}>
              Current Value
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={{ lineHeight: '16px', fontSize: '14px' }}>{currentValue || 'Null'} </Typography>
          </Grid>
        </Grid>
        {/* Smart Recommendation */}
        <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
          <Grid item>
            <Typography sx={{ color: theme.palette.neutral.n400, lineHeight: '16px', fontSize: '12px' }}>
              Smart Recommendation
            </Typography>
          </Grid>
          <Grid item>
            {loadingRecommendation === true ? (
              <SmartRecommendationLoading />
            ) : (
              <SmartRecommendationField
                handleGetAnomalyFieldRecommendation={handleGetAnomalyFieldRecommendation}
                haveRecommendation={haveRecommendation}
                recommendationCalculated={recommendationCalculated}
              />
            )}
          </Grid>
        </Grid>
        {/* Divider */}
        <Divider sx={{ width: '100%' }} />
        {/* Recommendation Feedback */}
        {haveRecommendation ? (
          <RecommendationFeedBack
            recommendationId={aiRecommendationKey}
            recommendationFeedBack={recommendationFeedBack}
            migrationId={migrationId}
          />
        ) : (
          <></>
        )}
      </Grid>
      <DataAnomalyCardMenu
        anchorEl={menuAnchorEl}
        open={menuOpen}
        handleClose={handleMenuClose}
        handleAcceptRecommendation={handleAcceptRecommendation}
        handleCreateTask={handleOpenCreateActivityMenu}
        actionLoading={menuActionLoading}
      />
    </>
  );
};
