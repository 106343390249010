import React from 'react';
import { Grid, Typography, Tooltip, LinearProgress, Skeleton } from '@mui/material';

import { Show } from 'components/show';
import { MigrationTable } from 'store/migration/types';
import LinearProgressCustom from 'components/LinearProgress/LinearProgress';

export type DataHealthMetricsProps = {
  migrationTable?: MigrationTable | null;
};

export const DataHealthMetrics: React.FC<DataHealthMetricsProps> = ({ migrationTable }) => {
  return (
    <Grid container display={'flex'} width={'50%'} bgcolor={'#F6F8FB'} borderRadius={1} px={2} py={1}>
      <Show when={!!migrationTable} fallback={<Skeleton height={40} width={'100%'} />}>
        <Grid item xs={4}>
          <Typography textTransform={'uppercase'} fontSize={10} color={'#898EA1'}>
            avg. confidence score
          </Typography>
          <LinearProgressCustom
            value={Number(migrationTable?.avg_confidence_score ?? 0)}
            justifyContent="start"
            fontSize={'10px'}
            borderRadius={'4px'}
            width={'100px'}
            height={'24px'}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography textTransform={'uppercase'} fontSize={10} color={'#898EA1'}>
            total no. of records
          </Typography>
          <Typography variant="h3" pt={1} fontSize={20}>
            {migrationTable?.row_count ?? 0}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography textTransform={'uppercase'} fontSize={10} color={'#898EA1'}>
            total records impacted
          </Typography>
          <Typography variant="h3" pt={1} fontSize={20}>
            823
          </Typography>
        </Grid>
      </Show>
    </Grid>
  );
};

export default DataHealthMetrics;
