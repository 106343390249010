import { StepIconProps } from '@mui/material';
import { Box } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const CustomStepIcon = ({ active, onClick }: StepIconProps) => {
  const Icon = active ? KeyboardArrowUpIcon : KeyboardArrowDownIcon;
  return (
    <Box
      padding={0.5}
      display="flex"
      borderRadius={100}
      alignItems="center"
      justifyContent="center"
      border="1px solid #EDF0F5"
      onClick={onClick}
    >
      <Icon htmlColor="#1554FF" fontSize="small" />
    </Box>
  );
};

export default CustomStepIcon;
