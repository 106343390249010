import qs from 'qs';
import io from 'socket.io-client';

import { FC, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import theme from 'core/theme';
import { Box, Divider, Grid, LinearProgress, Stack, Typography } from '@mui/material';

import { useAuth } from 'store/auth/hooks';
import { SubPages } from 'store/ruleLibrary/types';
import { useMigration } from 'store/migration/hooks';

import { useRuleLibrary } from 'store/ruleLibrary/hook';
import { getSortedArray } from 'store/ruleLibrary/utils';

import { TenantUpdateEventDto } from 'core/types';
import { PRIVATE_ABS_ROUTE_PATHS, TENANT_UPDATES_EVENT_TYPES } from 'core/constants';

import { Show } from 'components/show';
import { ModalTemplate } from 'components/ModalTemplate';
import { LoadingComponent, SecondaryButton, VerticalDivider } from './ui';

import { RuleLibraryList } from './components/RuleLibraryList';
import { RuleTemplateList } from './components/RuleTemplateList';
import { SubPageList as SubPageMenuList } from './components/SubPageList';

import { SearchRuleDropDown } from './components/SearchRuleDropDown';
import { TemporaryTableList } from './components/TemporaryTableList';

import { RuleFormModal } from './components/RuleFormModal/RuleFormModal';
import { TemporalTableModal } from './components/TemporalTableModal/TemporalTableModal';
import { useRuleFormModalHook } from './components/RuleFormModal/RuleFormModal.hook';

const SubPageListComponents: Record<keyof SubPages, JSX.Element> = {
  library: <RuleLibraryList />,
  template: <RuleTemplateList />,
  temporalTable: <TemporaryTableList />,
};

const DataRaptorPage: FC = () => {
  const navigate = useNavigate();
  const { accessToken } = useAuth();

  const {
    action: {
      postEmbedDDLSchema: { error: postEmbedDDLSchemaError, loading: postEmbedDDLSchemaLoading },
      getAIPoweredRuleTemplate: { error: getAIPoweredRuleTemplateError, loading: getAIPoweredRuleTemplateLoading },
    },
    subPages,
    selectedSubPage,
    getAllRulesByMigrationId,
    updateRulesByMigrationId,
    getRuleTemplatesByDataSourceId,
    getAllTemporaryTableByMigrationId,
    setRenderedRule,
    setFilteredRules,
    setRenderedTemplates,
    setFilteredTemplates,
    setFilteredTempTable,
    setRenderedTempTables,
  } = useRuleLibrary();

  const location = useLocation();
  const { openRuleFormModalForCreation, openRuleFormModalForUpdate } = useRuleFormModalHook();

  useEffect(() => {
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    const { migrationId, ruleId, type, action } = queryParams;
    if (migrationId && ruleId && type && action) {
      if (type === 'rule' && action === 'update') {
        setMigrationId(migrationId as string);
        openRuleFormModalForUpdate(migrationId as string, ruleId as string);
      }
    }
  }, []);

  const templateActionLoading = useMemo(() => {
    return postEmbedDDLSchemaLoading || getAIPoweredRuleTemplateLoading;
  }, [getAIPoweredRuleTemplateLoading, postEmbedDDLSchemaLoading]);

  const templateActionError = useMemo(() => {
    return getAIPoweredRuleTemplateError || postEmbedDDLSchemaError;
  }, [getAIPoweredRuleTemplateError, postEmbedDDLSchemaError]);

  const {
    getMigrations,
    setMigrationId,
    cleanState: cleanStateMigration,
    data: { migrationId, migrations },
  } = useMigration();

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_URL;
    if (!apiUrl) return;
    const newSocket = io(apiUrl, {
      extraHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    newSocket.on('tenant-update', (payload: TenantUpdateEventDto) => {
      if (payload.type === TENANT_UPDATES_EVENT_TYPES.RULE_APPLIED && migrationId) {
        console.warn(payload);
        updateRulesByMigrationId({ migrationId });
      }
    });

    newSocket.emit('join-tenant-updates-group', {});
    return () => {
      newSocket.disconnect();
      newSocket.off();
    };
  }, [accessToken, updateRulesByMigrationId, migrationId]);

  useEffect(() => {
    cleanStateMigration();
  }, []);

  useEffect(() => {
    if (!migrations || migrations.length === 0) {
      getMigrations({
        onSuccess: (migrations) => {
          if (migrations.length === 0) navigate(PRIVATE_ABS_ROUTE_PATHS.dataRaptor);
        },
      });
    }
  }, [getMigrations, migrations, navigate]);

  useEffect(() => {
    if (!migrationId && migrations && migrations.length > 0) {
      const migrationsCompleted = migrations.filter((migration) => migration.status === 'migration-completed');
      if (migrationsCompleted.length > 0) {
        setMigrationId(migrations[0].dataMigrationId);
      } else {
        navigate(PRIVATE_ABS_ROUTE_PATHS.dataRaptor);
      }
    }
  }, [migrationId, migrations, navigate, setMigrationId]);

  useEffect(() => {
    if (migrationId) {
      //Get Rules Records
      getAllRulesByMigrationId({ migrationId });
      //Get Template Records
      getRuleTemplatesByDataSourceId({ dataSourceId: 'Salesforce' });
      // Get Temporary Tables
      getAllTemporaryTableByMigrationId({ migrationId });
    }
  }, [getAllRulesByMigrationId, getAllTemporaryTableByMigrationId, getRuleTemplatesByDataSourceId, migrationId]);

  useEffect(() => {
    setFilteredRules(subPages.library.data.rules);
  }, []);

  useEffect(() => {
    setFilteredTemplates(subPages.template.data.templates);
  }, []);

  useEffect(() => {
    setFilteredTempTable(subPages.temporalTable.data.tables);
  }, [setFilteredTempTable, subPages.temporalTable.data.tables]);

  useEffect(() => {
    setRenderedTempTables(subPages.temporalTable.data.filteredTables);
  }, [setRenderedTempTables, subPages.temporalTable.data.filteredTables]);

  useEffect(() => {
    if (subPages.library.data.sortStrategy) {
      setRenderedRule(getSortedArray(subPages.library.data.rules, subPages.library.data.sortStrategy) as any);
    } else {
      setRenderedRule(subPages.library.data.filteredRules);
    }
  }, [
    setRenderedRule,
    subPages.library.data.filteredRules,
    subPages.library.data.rules,
    subPages.library.data.sortStrategy,
  ]);

  useEffect(() => {
    if (subPages.template.data.sortStrategy) {
      setRenderedTemplates(
        getSortedArray(subPages.template.data.templates, subPages.template.data.sortStrategy) as any,
      );
    } else {
      setRenderedTemplates(subPages.template.data.filteredTemplates);
    }
  }, [
    setRenderedTemplates,
    subPages.template.data.filteredTemplates,
    subPages.template.data.sortStrategy,
    subPages.template.data.templates,
  ]);

  return (
    <>
      <Box sx={{ backgroundColor: 'darkBg.main', display: 'flex', flex: 1, flexDirection: 'column' }}>
        <Grid container sx={{ backgroundColor: 'neutral.white', padding: '24px 32px 16px' }}>
          <Grid item xs={12} sm={6}>
            <Stack direction="row" alignItems="flex-end">
              <Typography variant="h2" sx={{ color: 'neutral.main' }}>
                Rule Library
              </Typography>
              <VerticalDivider />
              <Typography variant="labelRegular12" sx={{ color: 'neutral.n400', my: 'auto' }} component="p">
                Data health and anomaly detection solution
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack width={'100%'} direction="row-reverse" display={'flex'} alignItems="flex-end" gap={1}>
              <Box>
                <SecondaryButton onClick={openRuleFormModalForCreation}>Create Rule</SecondaryButton>
              </Box>
              <Box flex={4} mr={1} minWidth={'150px'}>
                <SearchRuleDropDown />
              </Box>
            </Stack>
          </Grid>
          {!!templateActionError && (
            <Typography variant="caption" sx={{ color: 'red.main', display: 'block', ml: 'auto', mt: '0.5rem' }}>
              {templateActionError}
            </Typography>
          )}
        </Grid>
        <Divider color={theme.palette.neutral.subtone310} />
        <SubPageMenuList />
        <Grid item display={'flex'} flex={1} sx={{ paddingY: '32px', paddingX: '16px' }}>
          <Show fallback={<LoadingComponent />} when={!subPages[selectedSubPage].loading}>
            {SubPageListComponents[selectedSubPage]}
          </Show>
        </Grid>
      </Box>
      <>
        <ModalTemplate
          title={`Loading...`}
          showChevronDown={false}
          showCloseButton={false}
          open={templateActionLoading}
          toggleOpen={() => undefined}
        >
          <Grid container display={'flex'} flexDirection={'column'} spacing={3} py={5} px={5}>
            <Grid item sx={{ textAlign: 'center' }}>
              <Typography variant="h3">
                <strong>Generating your rule based on your data schema</strong>
              </Typography>
            </Grid>
            <Grid item>
              <LinearProgress />
            </Grid>
          </Grid>
        </ModalTemplate>
        <TemporalTableModal />
        <RuleFormModal />
      </>
    </>
  );
};

export default DataRaptorPage;
