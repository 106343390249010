import { PRIVATE_ABS_ROUTE_PATHS } from 'core/constants';
import useRuleForm from 'pages/RuleLibrary/hooks/RuleFormHook';
import { RuleFormMode } from 'pages/RuleLibrary/types';
import { getDataValidationEmptyRootConditional } from 'pages/RuleForm/utils';
import qs from 'qs';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRuleFormStore } from 'store/ruleForm/hook';
import { FrontEndRuleDto } from 'store/dataRaptorRule/dto/front-end-rule.dto';

export const useRuleFormModalHook = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    setRuleLibraryModalOpen,
    setRuleLibraryModalMenuIndex,
    subQueries,
    setDepartment,
    setDescription,
    setFormErrorObject,
    setHaving,
    setRiskLevel,
    setRuleName,
    setSubQueries,
    setViolationScore,
    setCategory,
    setWhere,
    setRuleLibraryModalLoading,
    setRuleLibraryModalLoadingMessage,
  } = useRuleFormStore();

  const {
    setSelectedTable,
    handleAddSubQueryObject,
    updateDataFromRule,
    updateDataFromRuleTemplate,
    setFormMode,
    setSelectedRuleId,
    selectedTable,
    tableOptions,
  } = useRuleForm();

  const openRuleFormModalForCreation = useCallback(() => {
    setFormMode(RuleFormMode.CREATE);
    setRuleLibraryModalOpen(true);
    setCategory('data-validation');
    setRiskLevel('low');
    setDepartment('others');
  }, [setCategory, setDepartment, setFormMode, setRiskLevel, setRuleLibraryModalOpen]);

  const openRuleFormModalForCreationFromTemplate = useCallback(
    (ruleTemplateProvided?: FrontEndRuleDto) => {
      updateDataFromRuleTemplate(ruleTemplateProvided);
      setFormMode(RuleFormMode.CREATE_FROM_TEMPLATE);
      setRuleLibraryModalOpen(true);
    },
    [setFormMode, setRuleLibraryModalOpen, updateDataFromRuleTemplate],
  );

  const openRuleFormModalForUpdate = useCallback(
    (migrationId: string, ruleId: string, onError?: (error: any) => void) => {
      setFormMode(RuleFormMode.EDIT);
      setRuleLibraryModalOpen(true);
      setRuleLibraryModalLoading(true);
      setRuleLibraryModalLoadingMessage('Loading rule data...');
      const onSuccess = () => {
        setRuleLibraryModalLoading(false);
        setRuleLibraryModalLoadingMessage('');
      };
      updateDataFromRule(migrationId, ruleId, onError, onSuccess);
    },
    [
      setFormMode,
      setRuleLibraryModalLoading,
      setRuleLibraryModalLoadingMessage,
      setRuleLibraryModalOpen,
      updateDataFromRule,
    ],
  );

  const handleCloseModal = useCallback(() => {
    setRuleLibraryModalOpen(false);
    setRuleLibraryModalLoading(false);
    setRuleLibraryModalLoadingMessage('');
    setRuleLibraryModalMenuIndex(0);
    setSelectedTable('');
    setRuleName('');
    setRiskLevel('');
    setDepartment('');
    setDescription('');
    setCategory('');
    setViolationScore(0);
    setHaving({ value: [] });
    setFormErrorObject({});
    setSelectedRuleId('');
    setSubQueries({ value: [] });
    setWhere({ value: [getDataValidationEmptyRootConditional()] });

    const querySearch = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (querySearch.type === 'rule' && querySearch.action) {
      navigate(PRIVATE_ABS_ROUTE_PATHS.ruleLibrary);
    }
  }, [
    location.search,
    setRuleLibraryModalOpen,
    setRuleLibraryModalLoading,
    setRuleLibraryModalLoadingMessage,
    setRuleLibraryModalMenuIndex,
    setSelectedTable,
    setRuleName,
    setRiskLevel,
    setDepartment,
    setDescription,
    setCategory,
    setViolationScore,
    setHaving,
    setFormErrorObject,
    setSelectedRuleId,
    setSubQueries,
    setWhere,
    navigate,
  ]);

  return {
    data: { selectedTable, subQueries, tableOptions },
    handleCloseModal,
    setRuleLibraryModalMenuIndex,
    setSelectedTable,
    handleAddSubQueryObject,
    openRuleFormModalForCreation,
    openRuleFormModalForUpdate,
    openRuleFormModalForCreationFromTemplate,
  };
};
